import React from 'react';
import { inject, observer } from 'mobx-react';
import { Route } from 'react-router-dom';

import { IS_PECALICE } from '@SUPPORT/utils';

import { Header } from './Header';
import { IdentifierForm } from './IdentifierForm';
import { PasswordForm } from './PasswordForm';
import { UnknownUserInfoForm } from './UnknownUserInfoForm';
import { UnknownPescaliceUser } from './UnknownPescaliceUser';
import { UnknownUserConfirmation } from './UnknownUserConfirmation';
import { MissingInfoForm } from './MissingInfoForm';
// import EmailConfirmation from './EmailConfirmation';
import { SMSValidationForm } from './SMSValidationForm';
import { PasswordResetForm } from './PasswordResetForm';
import { PasswordResetConfirmation } from './PasswordResetConfirmation';
import { CountryPanel } from './CountryPanel';
import { UserInfoRecap } from './UserInfoRecap';

import { Copyright } from '@COMPONENTS/common/Copyright';
import { ModalBackground } from '@COMPONENTS/common/ModalBackground';
import { ErrorBoundary } from '@COMPONENTS/common/ErrorBoundary';

@inject('store')
@observer
export class LoginRoot extends React.Component {
    componentDidMount() {
        document.title = `${IS_PECALICE ? 'Pescalice' : 'Telecapêche'} - Login`;
        this.props.store.loadInitialData();
    }

    render() {
        return (
            <ErrorBoundary>
                <Header />
                <div id="content">
                    <div className="formsLogin animate">
                        <Route exact path="/login" component={IdentifierForm} />
                        <Route exact path="/login" component={PasswordForm} />
                        <Route exact path="/login" component={SMSValidationForm} />
                        <Route exact path="/login" component={UnknownUserInfoForm} />
                        <Route exact path="/login" component={UnknownPescaliceUser} />
                        <Route exact path="/login" component={UnknownUserConfirmation} />
                        <Route exact path="/login" component={MissingInfoForm} />
                        <Route exact path="/login" component={UserInfoRecap} />
                        <Route exact path="/login" render={() => <PasswordResetForm create />} />

                        <Route exact path="/pwd_reset/:id" render={() => <PasswordResetForm reset />} />
                        <Route exact path="/pwd_reset/:id" component={PasswordResetConfirmation} />
                    </div>
                </div>
                <Copyright withLegal withBigLogo={!IS_PECALICE} />

                <ModalBackground display={this.props.store.displayCountryPanel} />
                <CountryPanel />
            </ErrorBoundary>
        );
    }
}

//                             <Route exact path="/login" component={EmailConfirmation} />
