import classNames from 'classnames';
import { types, flow } from 'mobx-state-tree';

import { User } from '@STORES/common/user';
import { Country } from '@STORES/common/country';
import { AppStore } from '@STORES/common/app';
import { SessionStore } from '@STORES/common/session';

import { api } from '@SUPPORT/api';
import { IS_PECALICE } from '@SUPPORT/utils';

// Blocks states, transitions and related classes:
const LEFT = 'left';
const RIGHT = 'right';
// prettier-ignore
const BLOCK_CLASSES = new Map([
    ['identifierForm',                                 { identifierForm: null, passwordForm: RIGHT, emailConfirmation: RIGHT, smsConfirmation: RIGHT, unknownUserInfoForm: RIGHT, unknownPescaliceUser: RIGHT, unknownUserConfirmation: RIGHT, missingInfoForm: RIGHT, smsValidationForm: RIGHT, passwordResetForm: RIGHT, userInfoRecap: RIGHT, newPasswordForm: RIGHT }],
    ['identifierForm -> passwordForm',                 { identifierForm: LEFT, passwordForm: null,  emailConfirmation: RIGHT, smsConfirmation: RIGHT, unknownUserInfoForm: RIGHT, unknownPescaliceUser: RIGHT, unknownUserConfirmation: RIGHT, missingInfoForm: RIGHT, smsValidationForm: RIGHT, passwordResetForm: RIGHT, userInfoRecap: RIGHT, newPasswordForm: RIGHT }],
    ['passwordForm -> identifierForm',                 { identifierForm: null, passwordForm: RIGHT, emailConfirmation: RIGHT, smsConfirmation: RIGHT, unknownUserInfoForm: RIGHT, unknownPescaliceUser: RIGHT, unknownUserConfirmation: RIGHT, missingInfoForm: RIGHT, smsValidationForm: RIGHT, passwordResetForm: RIGHT, userInfoRecap: RIGHT, newPasswordForm: RIGHT }],
    ['passwordForm -> emailConfirmation',              { identifierForm: LEFT, passwordForm: LEFT,  emailConfirmation: null,  smsConfirmation: RIGHT, unknownUserInfoForm: RIGHT, unknownPescaliceUser: RIGHT, unknownUserConfirmation: RIGHT, missingInfoForm: RIGHT, smsValidationForm: RIGHT, passwordResetForm: RIGHT, userInfoRecap: RIGHT, newPasswordForm: RIGHT }],
    ['passwordForm -> smsConfirmation',                { identifierForm: LEFT, passwordForm: LEFT,  emailConfirmation: RIGHT, smsConfirmation: null,  unknownUserInfoForm: RIGHT, unknownPescaliceUser: RIGHT, unknownUserConfirmation: RIGHT, missingInfoForm: RIGHT, smsValidationForm: RIGHT, passwordResetForm: RIGHT, userInfoRecap: RIGHT, newPasswordForm: RIGHT }],
    ['identifierForm -> unknownPescaliceUser',         { identifierForm: LEFT, passwordForm: RIGHT, emailConfirmation: RIGHT, smsConfirmation: RIGHT, unknownUserInfoForm: RIGHT, unknownPescaliceUser: null,  unknownUserConfirmation: RIGHT, missingInfoForm: RIGHT, smsValidationForm: RIGHT, passwordResetForm: RIGHT, userInfoRecap: RIGHT, newPasswordForm: RIGHT }],
    ['unknownPescaliceUser -> identifierForm',         { identifierForm: null, passwordForm: RIGHT, emailConfirmation: RIGHT, smsConfirmation: RIGHT, unknownUserInfoForm: RIGHT, unknownPescaliceUser: RIGHT, unknownUserConfirmation: RIGHT, missingInfoForm: RIGHT, smsValidationForm: RIGHT, passwordResetForm: RIGHT, userInfoRecap: RIGHT, newPasswordForm: RIGHT }],
    ['identifierForm -> unknownUserInfoForm',          { identifierForm: LEFT, passwordForm: RIGHT, emailConfirmation: RIGHT, smsConfirmation: RIGHT, unknownUserInfoForm: null,  unknownPescaliceUser: RIGHT, unknownUserConfirmation: RIGHT, missingInfoForm: RIGHT, smsValidationForm: RIGHT, passwordResetForm: RIGHT, userInfoRecap: RIGHT, newPasswordForm: RIGHT }],
    ['unknownUserInfoForm -> identifierForm',          { identifierForm: null, passwordForm: RIGHT, emailConfirmation: RIGHT, smsConfirmation: RIGHT, unknownUserInfoForm: RIGHT, unknownPescaliceUser: RIGHT, unknownUserConfirmation: RIGHT, missingInfoForm: RIGHT, smsValidationForm: RIGHT, passwordResetForm: RIGHT, userInfoRecap: RIGHT, newPasswordForm: RIGHT }],
    ['unknownUserInfoForm -> unknownUserConfirmation', { identifierForm: LEFT, passwordForm: LEFT,  emailConfirmation: RIGHT, smsConfirmation: RIGHT, unknownUserInfoForm: LEFT,  unknownPescaliceUser: LEFT,  unknownUserConfirmation: null , missingInfoForm: RIGHT, smsValidationForm: RIGHT, passwordResetForm: RIGHT, userInfoRecap: RIGHT, newPasswordForm: RIGHT }],
    ['identifierForm -> missingInfoForm',              { identifierForm: LEFT, passwordForm: LEFT,  emailConfirmation: RIGHT, smsConfirmation: RIGHT,  unknownUserInfoForm: LEFT,  unknownPescaliceUser: LEFT, unknownUserConfirmation: RIGHT, missingInfoForm: null,  smsValidationForm: RIGHT, passwordResetForm: RIGHT, userInfoRecap: RIGHT, newPasswordForm: RIGHT }],
    ['missingInfoForm -> unknownUserConfirmation',     { identifierForm: LEFT, passwordForm: LEFT,  emailConfirmation: RIGHT, smsConfirmation: RIGHT, unknownUserInfoForm: LEFT,  unknownPescaliceUser: LEFT,  unknownUserConfirmation: null , missingInfoForm: LEFT,  smsValidationForm: RIGHT, passwordResetForm: RIGHT, userInfoRecap: RIGHT, newPasswordForm: RIGHT }],
    ['identifierForm -> smsValidationForm',            { identifierForm: LEFT, passwordForm: LEFT,  emailConfirmation: RIGHT, smsConfirmation: RIGHT, unknownUserInfoForm: RIGHT, unknownPescaliceUser: RIGHT, unknownUserConfirmation: RIGHT, missingInfoForm: RIGHT, smsValidationForm: null,  passwordResetForm: RIGHT, userInfoRecap: RIGHT, newPasswordForm: RIGHT }],
    ['passwordForm -> smsValidationForm',              { identifierForm: LEFT, passwordForm: LEFT,  emailConfirmation: RIGHT, smsConfirmation: RIGHT, unknownUserInfoForm: RIGHT, unknownPescaliceUser: RIGHT, unknownUserConfirmation: RIGHT, missingInfoForm: RIGHT, smsValidationForm: null,  passwordResetForm: RIGHT, userInfoRecap: RIGHT, newPasswordForm: RIGHT }],
    ['smsValidationForm -> passwordResetForm',         { identifierForm: LEFT, passwordForm: LEFT,  emailConfirmation: RIGHT, smsConfirmation: RIGHT, unknownUserInfoForm: RIGHT, unknownPescaliceUser: RIGHT, unknownUserConfirmation: RIGHT, missingInfoForm: RIGHT, smsValidationForm: LEFT,  passwordResetForm: null,  userInfoRecap: RIGHT, newPasswordForm: RIGHT }],
    ['smsValidationForm -> userInfoRecap',             { identifierForm: LEFT, passwordForm: LEFT,  emailConfirmation: LEFT,  smsConfirmation: LEFT,  unknownUserInfoForm: LEFT,  unknownPescaliceUser: LEFT,  unknownUserConfirmation: LEFT,  missingInfoForm: LEFT,  smsValidationForm: LEFT,  passwordResetForm: LEFT,  userInfoRecap: null,  newPasswordForm: RIGHT }],
    ['smsValidationForm -> identifierForm',            { identifierForm: null, passwordForm: RIGHT, emailConfirmation: RIGHT, smsConfirmation: RIGHT, unknownUserInfoForm: RIGHT, unknownPescaliceUser: RIGHT, unknownUserConfirmation: RIGHT, missingInfoForm: RIGHT, smsValidationForm: RIGHT, passwordResetForm: RIGHT, userInfoRecap: RIGHT, newPasswordForm: RIGHT }],
    ['userInfoRecap -> newPasswordForm',               { identifierForm: LEFT, passwordForm: LEFT,  emailConfirmation: LEFT,  smsConfirmation: LEFT,  unknownUserInfoForm: LEFT,  unknownPescaliceUser: LEFT,  unknownUserConfirmation: LEFT,  missingInfoForm: LEFT,  smsValidationForm: LEFT,  passwordResetForm: LEFT,  userInfoRecap: LEFT,  newPasswordForm: null  }],
]);

const PasswordResetRequest = types.model('PasswordResetRequest', {
    id: types.maybeNull(types.number),
    userId: types.number,
    userEmail: types.maybeNull(types.string),
    mobileNum: types.maybeNull(types.string)
});

const LoginStore = types
    .model('LoginStore', {
        app: types.optional(AppStore, {}),
        session: types.optional(SessionStore, {}),

        displayCountryPanel: false,
        countries: types.array(Country),

        blockState: types.string,
        blockData: types.frozen(),
        blockClasses: types.frozen(),

        identifier: types.maybeNull(types.string),
        password: types.maybeNull(types.string),
        lastConnectionDate: types.maybeNull(types.string),
        user: types.maybeNull(User),
        resetRequest: types.maybeNull(PasswordResetRequest)
    })
    .views((self) => {
        return {
            blockClass(block) {
                const cls = self.blockClasses[block];
                return classNames({ left: cls === 'left', right: cls === 'right' });
            },

            countryByCode: (code) => {
                return self.countries.find((country) => country.code === code);
            }
        };
    })
    .actions((self) => {
        return {
            loadInitialData: flow(function* () {
                const response = yield api.listCountries();
                self.countries = response.data || [];
                self.app.setReady();
            }),

            transitionToBlock: (state, data = null) => {
                const transitionKey = `${self.blockState} -> ${state}`;
                if (BLOCK_CLASSES.has(transitionKey)) {
                    self.blockClasses = BLOCK_CLASSES.get(transitionKey);
                    self.blockState = state;
                    self.blockData = data;
                } else {
                    throw new Error('Trying to perform invalid block transition');
                }
            },

            setDisplayCountryPanel: (display) => {
                self.displayCountryPanel = display;
            },

            setIdentifier: flow(function* (identifier) {
                if (!identifier) {
                    return false;
                }

                self.identifier = identifier;

                self.app.setBusy();
                try {
                    const response = yield api.checkIdentifier(identifier);
                    const isValid =
                        response.status === 'ok' && response.data.lastConnectionDate && !response.data.status;
                    if (isValid) {
                        self.lastConnectionDate = response.data.lastConnectionDate;
                        self.transitionToBlock('passwordForm');
                    } else if (response.status === 'ok' && response.data.status === 'codeSent') {
                        self.transitionToBlock('smsValidationForm', response.data);
                    } else if (response.status === 'error' && response.data.missingFields) {
                        self.transitionToBlock('missingInfoForm', response.data);
                    } else if (response.data.status === 'missingMobileNum') {
                        alert(
                            "Données utilisateur incomplètes. Le numéro de mobile est nécessaire pour pouvoir s'identifier une première fois."
                        );
                    }

                    return isValid;
                } catch (err) {
                    if (err.name === 'APIError' && err.code === 404) {
                        if (IS_PECALICE) {
                            self.transitionToBlock('unknownPescaliceUser');
                        } else {
                            self.transitionToBlock('unknownUserInfoForm');
                        }
                    }
                } finally {
                    self.app.setBusy(false);
                }
            }),

            setPassword: flow(function* (password) {
                self.password = password;
                yield self.session.create(self.identifier, self.password);
            }),

            createNewPassword: flow(function* (newPassword) {
                self.app.setBusy();
                try {
                    yield api.createPassword(self.user.id, newPassword);
                    self.session.create(self.user.identifier, newPassword);
                } finally {
                    self.app.setBusy(false);
                }
            }),

            sendProfileRequest: flow(function* (params) {
                self.app.setBusy();
                try {
                    yield api.sendProfileRequest(params);
                } finally {
                    self.app.setBusy(false);
                }
            }),

            requestPasswordReset: flow(function* (identifier) {
                self.app.setBusy();
                try {
                    const response = yield api.requestPasswordReset(identifier);
                    const request = PasswordResetRequest.create(response.data);
                    self.resetRequest = request;
                    self.transitionToBlock('smsValidationForm', response.data);
                } finally {
                    self.app.setBusy(false);
                }
            }),

            clearResetRequest: () => {
                self.resetRequest = null;
            },

            resetPassword: flow(function* (requestId, newPassword) {
                self.app.setBusy();
                // self.error = null;
                try {
                    yield api.resetPassword(requestId, newPassword);
                    self.session.create(self.user.identifier, newPassword);
                } catch (err) {
                    // if (err.name === 'APIError' && err.code === 400) {
                    //     self.error = 'INVALID_REQUEST_ID';
                    // }
                } finally {
                    self.app.setBusy(false);
                }
            }),

            verifySMSCode: flow(function* (code) {
                self.app.setBusy();
                try {
                    const response = yield api.verifySMSCode(self.identifier, code);
                    const verified = response.status === 'ok';
                    if (verified) {
                        self.user = response.data;
                        self.transitionToBlock('userInfoRecap');
                    }
                } finally {
                    self.app.setBusy(false);
                }
            }),

            validateUserInfo: () => {
                if (self.password) {
                    self.session.create(self.identifier, self.password);
                } else {
                    self.transitionToBlock('newPasswordForm');
                }
            },

            invalidateUserInfo: () => {
                const params = {
                    name: self.user.fullName(false),
                    email: self.user.email,
                    phone: self.user.mobileNum1 || self.user.mobileNum2
                };

                self.sendProfileRequest(params).then(() => {
                    self.transitionToBlock('unknownUserConfirmation');
                });
            }
        };
    });

export const store = LoginStore.create({
    countries: [],
    blockState: 'identifierForm',
    blockClasses: BLOCK_CLASSES.get('identifierForm')
});
