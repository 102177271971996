import React from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import ReactForm from 'mobx-react-form';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import { validator } from '@SUPPORT/validator';

@withRouter
@inject('store')
@withTranslation(['login', 'common'])
@observer
export class PasswordResetForm extends React.Component {
    constructor(props) {
        super(props);
        this.form = new ReactForm(
            {
                fields: {
                    password: { type: 'password', rules: 'required|between:4,64' },
                    passwordConfirmation: { type: 'password', rules: 'required|between:4,64|same:password' }
                }
            },
            {
                plugins: { dvr: validator },
                options: { validateOnChange: true }
            }
        );
    }

    _resetPassword = (evt) => {
        evt.preventDefault();

        const newPassword = this.form.select('password').value;
        if (this.props.reset) {
            const requestId = this.props.match.params.id;
            this.props.store.resetPassword(requestId, newPassword);
        } else if (this.props.create) {
            this.props.store.createNewPassword(newPassword);
        }
    };

    render() {
        const classes = this.props.reset
            ? classNames({ left: this.props.store.session.passwordResetSucceeded })
            : this.props.store.blockClass('newPasswordForm');

        const { t } = this.props;
        return (
            <form className={classes}>
                <h2>{t('login:pwd-reset-form.title')}</h2>
                <div>
                    <p>{t('login:pwd-reset-form.new-pwd-message')}</p>
                    <label htmlFor={this.form.select('password').id}>{t('login:pwd-reset-form.new-pwd-label')}</label>
                    <input {...this.form.select('password').bind()} />
                </div>
                <div>
                    <label htmlFor={this.form.select('passwordConfirmation').id}>
                        {t('login:pwd-reset-form.pwd-confirmation-label')}
                    </label>
                    <input {...this.form.select('passwordConfirmation').bind()} />
                </div>
                <div>
                    <button disabled={!this.form.isValid} onClick={this._resetPassword}>
                        {t('common:modify')}
                    </button>
                    <p className="hidden">{t('login:pwd-reset-form.invalid-password-error')}</p>
                </div>
            </form>
        );
    }
}

// <div id="pwdMeter" className="level1">
//     <ul>
//         <li />
//         <li />
//         <li />
//         <li />
//         <li />
//     </ul>
//     <i className="level0">Password strength</i>
//     <i className="level1">Very weak!</i>
//     <i className="level2">Weak</i>
//     <i className="level3">So-so</i>
//     <i className="level4">Good</i>
//     <i className="level5">Great!</i>
// </div>
