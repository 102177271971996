import React from 'react';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import { formatPhoneNumber } from '@SUPPORT/utils';

@inject('store')
@withTranslation(['login', 'common'])
@observer
export class UserInfoRecap extends React.Component {
    _validateInfo = (evt) => {
        evt.preventDefault();
        this.props.store.validateUserInfo();
    };

    _invalidateInfo = (evt) => {
        evt.preventDefault();
        this.props.store.invalidateUserInfo();
    };

    render() {
        const { t } = this.props;
        return (
            <form className={this.props.store.blockClass('userInfoRecap')}>
                <h2>{t('login:user-info-recap.title')}</h2>
                <div>
                    <p>
                        {t('login:user-info-recap.verify')}&nbsp;:
                        <br />
                        <br />
                        {this.props.store.user ? (
                            <React.Fragment>
                                <b>{this.props.store.user.fullName(false)}</b>
                                <br />
                                {this.props.store.user.address1}
                                {this.props.store.user.address1 ? <br /> : null}
                                {this.props.store.user.address2}
                                {this.props.store.user.address2 ? <br /> : null}
                                {this.props.store.user.zipCode} {this.props.store.user.city}
                                {this.props.store.user.zipCode || this.props.store.user.city ? <br /> : null}
                                <b>
                                    {t('common:email')} : {this.props.store.user.email}
                                </b>
                                <br />
                                <b>
                                    {t('common:mobile')} : {formatPhoneNumber(this.props.store.user.mobileNum1)}
                                </b>
                            </React.Fragment>
                        ) : null}
                    </p>
                </div>
                <div>
                    <button onClick={this._validateInfo}>{t('login:user-info-recap.info-ok')}</button>
                </div>
                <div>
                    <a href="#" onClick={this._rejectInfo}>
                        {t('login:user-info-recap.info-not-ok')}
                    </a>
                </div>
            </form>
        );
    }
}
