import React from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

@inject('store')
@withTranslation(['login'])
@observer
export class PasswordResetConfirmation extends React.Component {
    _navigateToLogin(evt) {
        evt.preventDefault();
        window.location.replace('/');
    }

    render() {
        const classes = classNames({ right: !this.props.store.session.passwordResetSucceeded });
        const { t } = this.props;
        return (
            <form className={classes}>
                <h2>{t('login:pwd-change-confirmation.title')}</h2>
                <div>
                    <p>{t('login:pwd-change-confirmation.message')}</p>
                </div>
                <div>
                    <button onClick={this._navigateToLogin}>{t('login:pwd-change-confirmation.continue')}</button>
                </div>
            </form>
        );
    }
}
