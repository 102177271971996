import validatorjs from 'validatorjs';

import { isValid, isBefore, endOfToday } from 'date-fns';

export function parseFDate(value) {
    if (value instanceof Date) {
        return value;
    }

    const match = (value || '').match(/(\d\d)\/(\d\d)\/(\d\d\d\d)/);
    if (!match) {
        return NaN;
    }

    return new Date(`${match[3]}-${match[2]}-${match[1]}`);
}

export function isoFDate(value) {
    const date = parseFDate(value);
    return isNaN(date) ? 'Invalid Date' : date.toISOString();
}

validatorjs.register(
    'fnumeric',
    (value) => {
        if (!value) {
            return false;
        }

        const num = Number(value.toString().replace(/,/, '.'));
        return typeof num === 'number' && !isNaN(num) && typeof val !== 'boolean';
    },
    'Invalid number'
);

validatorjs.register('ftime', (value) => {
    if (!value) {
        return false;
    }

    return isValid(new Date(`1970-01-01T${value}:00`)), 'Invalid time';
});

validatorjs.register(
    'fdate',
    (value) => {
        if (value instanceof Date) {
            return !isNaN(value);
        }

        if (!value) {
            return false;
        }

        return isValid(parseFDate(value));
    },
    'Invalid date'
);

validatorjs.register(
    'afterf',
    // Warning: do not turn this into an arrow function because it would
    // bind it incorrectly and make `this` point to the incorrect object
    function (value, req) {
        const date1 = parseFDate(this.validator.input[req] || '');
        const date2 = parseFDate(value);
        return isValid(date1) && isValid(date2) && date1.getTime() < date2.getTime();
    },
    'Date not after other'
);

validatorjs.register(
    'declDate',
    (value) => {
        if (!value) {
            return false;
        }

        const date = parseFDate(value);
        return isValid(date) && isBefore(date, endOfToday());
    },
    'Invalid date'
);

// registerImplicit to make it always run even if value is ""
validatorjs.registerImplicit(
    'requiredIfPAPRequester',
    function (value) {
        return (
            (value !== '' && this.validator.input.papRequester === true) || this.validator.input.papRequester === false
        );
    },
    'A social regime must be provided if the user is a PAP requester'
);

validatorjs.register(
    'requiresSocialRegime',
    function (value) {
        return value === false || this.validator.input.socialRegime !== '';
    },
    'A user cannot be a PAP requester if a social regime has not been provided'
);

import dvr from 'mobx-react-form/lib/validators/DVR';
export const rawValidator = validatorjs;
export const validator = dvr(validatorjs);
