/* global process */

import i18n from 'i18next';
import Backend from 'i18next-fetch-backend';
import { initReactI18next } from 'react-i18next';

import { fr, enGB, pt } from 'date-fns/locale';

const DATE_FNS_LOCALES = { fr, enGB, pt };
let CURRENT_FNS_LOCALE = null;
export function currentFnsLocale() {
    return CURRENT_FNS_LOCALE;
}

let FULL_MONEY_FORMATTER = null;
export function fullMoneyFormatter() {
    return FULL_MONEY_FORMATTER;
}

let MONEY_FORMATTER = null;
export function moneyFormatter() {
    return MONEY_FORMATTER;
}

i18n.use(Backend).use(initReactI18next);
export function initializeI18N(namespace, languageCode) {
    i18n.init({
        lng: languageCode,
        fallbackLng: null,
        lowerCaseLng: true,

        ns: ['common', namespace],
        defaultNS: namespace,

        debug: process.env.NODE_ENV === 'development',

        interpolation: {
            escapeValue: false // not needed for react!!
        },

        react: {
            wait: true,
            useSuspense: true
        },

        backend: {
            init: {
                cache: 'no-store'
            }
        }
    });
}

export function initializeLocale(languageCode, currencyCode) {
    if (languageCode === 'en') {
        CURRENT_FNS_LOCALE = DATE_FNS_LOCALES['enGB'];
    } else {
        CURRENT_FNS_LOCALE = DATE_FNS_LOCALES[languageCode || 'fr'];
    }

    MONEY_FORMATTER = new Intl.NumberFormat(languageCode, { style: 'decimal', minimumFractionDigits: 2 });
    FULL_MONEY_FORMATTER = new Intl.NumberFormat(languageCode, { style: 'currency', currency: currencyCode });
}

export { i18n };
