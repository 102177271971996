import { types } from 'mobx-state-tree';

import { Species } from './species';

export const BuyerOrg = types
    .model('BuyerOrg', {
        id: types.number,
        code: types.string,
        name: types.string,
        ownerName: types.maybeNull(types.string),
        ownerPhone: types.maybeNull(types.string),
        ownerEmail: types.maybeNull(types.string),
        creationDate: types.Date,
        companyEmail: types.maybeNull(types.string),
        phoneNumber: types.maybeNull(types.string),
        address1: types.maybeNull(types.string),
        address2: types.maybeNull(types.string),
        zipCode: types.maybeNull(types.string),
        city: types.maybeNull(types.string),
        country: types.maybeNull(types.string),
        countryCode: types.maybeNull(types.string),
        companyId: types.maybeNull(types.string),
        vatNum: types.maybeNull(types.string),
        accreditation: types.maybeNull(types.string),
        adminId: types.maybeNull(types.number),

        // When loading a user related buyer org, this contains the species
        // that the user already sold to the buyer
        species: types.maybeNull(types.array(types.reference(Species)))
    })
    .preProcessSnapshot(
        (snapshot) =>
            snapshot && {
                ...snapshot,
                creationDate:
                    typeof snapshot.creationDate === 'string' ? new Date(snapshot.creationDate) : snapshot.creationDate
            }
    );

export const BuyerOrgList = types.array(BuyerOrg);
