import React from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';

@inject('store')
@observer
export class Spinner extends React.Component {
    render() {
        const classes = classNames({ show: this.props.store.app.isBusy });
        return <div id="spinner" className={classes} style={this.props.style} />;
    }
}
