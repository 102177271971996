import React from 'react';
import ReactForm from 'mobx-react-form';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import { validator } from '@SUPPORT/validator';

@inject('store')
@withTranslation(['login', 'common'])
@observer
export class UnknownUserInfoForm extends React.Component {
    constructor(props) {
        super(props);
        this.form = new ReactForm(
            {
                fields: {
                    type: { type: 'select', rules: 'required' },
                    name: { type: 'text', rules: 'required' },
                    phone: { type: 'text', rules: 'required' },
                    email: { type: 'text', rules: 'required|email' }
                }
            },
            {
                plugins: { dvr: validator },
                options: { validateOnChange: true }
            }
        );
    }

    _sendRequest = (evt) => {
        evt.preventDefault();
        const params = Object.assign({ identifier: this.props.store.identifier || '' }, this.form.values());
        this.props.store.sendProfileRequest(params).then(() => {
            this.props.store.transitionToBlock('unknownUserConfirmation');
        });
    };

    _backToIdentifier = (evt) => {
        evt.preventDefault();
        this.props.store.transitionToBlock('identifierForm');
    };

    render() {
        const { t } = this.props;
        const identifier = this.props.store.identifier;
        return (
            <form className={this.props.store.blockClass('unknownUserInfoForm')}>
                <h2>
                    {identifier
                        ? t(`login:unknown-user-form.title-with-id`)
                        : t(`login:unknown-user-form.title-without-id`)}
                </h2>
                <div>
                    <label className="required">{t('login:unknown-user-form.type-label')} :</label>
                    <select {...this.form.select('type').bind()}>
                        <option>{t('common:select')}...</option>
                        <option value="PAP">{t('login:fisherman-type-selector.PAP')}</option>
                        <option value="PFP">{t('login:fisherman-type-selector.PFP')}</option>
                        <option value="PEP">{t('login:fisherman-type-selector.PEP')}</option>
                        <option value="PAE">{t('login:fisherman-type-selector.PAE')}</option>
                        <option value="PAL">{t('login:fisherman-type-selector.PAL')}</option>
                    </select>
                </div>
                <div>
                    <label>{t('login:unknown-user-form.type.name-and-first-name')} :</label>
                    <input {...this.form.select('name').bind()} />
                </div>
                <div>
                    <label>{t('common:mobile-number')} :</label>
                    <input {...this.form.select('phone').bind()} />
                </div>
                <div>
                    <label>{t('common:email')} :</label>
                    <input {...this.form.select('email').bind()} />
                </div>
                <div>
                    <button onClick={this._sendRequest} disabled={!this.form.isValid}>
                        {t('common:send')}
                    </button>
                </div>
                <div>
                    <a onClick={this._backToIdentifier} href="#">
                        {t('login:unknown-user-form.remember-id')}
                    </a>
                </div>
            </form>
        );
    }
}
