import { types, flow, getRoot } from 'mobx-state-tree';

import { api } from '@SUPPORT/api';

export const Species = types.model('Species', {
    code: types.identifier,
    name: types.string,
    scientificName: types.string,
    tags: types.maybeNull(types.array(types.string)),
    declarationsCount: types.maybeNull(types.number)
});

const SpeciesList = types.array(Species);

export const SpeciesStore = types
    .model('SpeciesStore', {
        list: types.optional(SpeciesList, []),
        orgList: types.array(types.reference(Species)),
        orgUnits: types.map(types.string),
        orgDecls: types.map(types.number)
    })
    .views((self) => {
        const _cache = new Map();
        return {
            byCode: (code) => {
                let species = _cache.get(code);
                if (!species) {
                    species = self.list.find((sp) => sp.code === code);
                    if (species) {
                        _cache.set(code, species);
                    }
                }

                return species;
            },

            withTag: (tag) => {
                return self.list.filter((sp) => sp.tags && sp.tags.includes(tag)) || [];
            },

            withAnyTag: (tags) => {
                if (!tags) {
                    return self.list;
                }
                return self.list.filter((sp) => sp.tags && sp.tags.some((tag) => tags.includes(tag))) || [];
            }
        };
    })
    .actions((self) => {
        const { app, session } = getRoot(self);
        return {
            listAll: flow(function* () {
                app.setBusy();
                try {
                    const response = yield api.listSpecies(app.language);
                    self.list = SpeciesList.create(response.data || []);
                } finally {
                    app.setBusy(false);
                }
            }),

            listOrgSpecies: flow(function* () {
                app.setBusy();
                try {
                    const sessionInfo = yield session.getSession();
                    const response = yield api.listOrgSpecies(sessionInfo.org.id, app.language);
                    self.orgList = response.data.map((info) => info.code);
                    self.orgDecls = response.data.reduce((decls, info) => {
                        decls[info.code] = info.declarationsCount;
                        return decls;
                    }, {});
                    self.orgUnits = response.data.reduce((units, info) => {
                        units[info.code] = info.unit || 'kg';
                        return units;
                    }, {});
                } finally {
                    app.setBusy(false);
                }
            })
        };
    });
