import React from 'react';

export function SVGObject(props) {
    const { sourceFile, objectId, className, ...svgProps } = props;
    const href = `/graphics/${sourceFile || 'symbols'}.svg#${objectId}`;
    return (
        <svg className={className} {...svgProps}>
            <use xlinkHref={href} />
        </svg>
    );
}
