import queryString from 'query-string';

import { setCookie, getCookie, clearCookie } from '@SUPPORT/utils';

const COOKIE_SESSION_KEY = 'telecapeche-session-id';

function APIError(code, message, wrapped = null) {
    this.name = 'APIError';
    this.code = code;
    this.message = message || 'API error';
    this.wrapped = wrapped;
    this.stack = new Error().stack;
}

APIError.prototype = Object.create(Error.prototype);
APIError.prototype.constructor = APIError;

class Client {
    constructor() {
        this._sessionId = getCookie(COOKIE_SESSION_KEY);
        this._userIdentifier = 'unknown';
    }

    get sessionId() {
        return this._sessionId;
    }

    setUserInfo(identifier, type) {
        this._userIdentifier = identifier || 'unknown';
        this._userType = type || 'unknown';
    }

    // SESSIONS

    createSession(identifier, password) {
        const data = new FormData();
        data.append('identifier', identifier);
        data.append('password', password);
        return this._fetch('/api/session', { params: { method: 'POST', body: data } }).then((response) => {
            this._sessionId = response.data.id;
            this._userIdentifier = identifier;
            setCookie(COOKIE_SESSION_KEY, response.data.id, 2);
            return response;
        });
    }

    getSession() {
        if (!this._sessionId) {
            return Promise.resolve();
        }

        return this._fetch('/api/session');
    }

    destroySession() {
        return this._fetch('/api/session', { params: { method: 'DELETE' } }).then(() => {
            clearCookie(COOKIE_SESSION_KEY);
        });
    }

    createPassword(userId, password) {
        const data = new FormData();
        data.append('password', password);
        return this._fetch(`/api/user/${userId}/password`, { params: { method: 'POST', body: data } });
    }

    requestPasswordReset(identifier) {
        const data = new FormData();
        data.append('identifier', identifier);
        return this._fetch('/api/request/password', { params: { method: 'POST', body: data } });
    }

    resetPassword(requestId, newPassword) {
        const data = new FormData();
        data.append('requestId', requestId);
        data.append('password', newPassword);
        return this._fetch('/api/password', { params: { method: 'PATCH', body: data } });
    }

    checkIdentifier(identifier) {
        return this._fetch(`/api/identifier/${identifier}`);
    }

    sendProfileRequest(params) {
        const data = new FormData();
        Object.keys(params).forEach((k) => data.append(k, params[k]));

        return this._fetch('/api/request/profile', { params: { method: 'POST', body: data } });
    }

    acceptConditions(identifier) {
        return this._fetch(`/api/identifier/${identifier}/conditions`, { params: { method: 'POST' } });
    }

    verifySMSCode(identifier, code) {
        const data = new FormData();
        data.append('mobileCode', code);

        return this._fetch(`/api/identifier/${identifier}/verify`, { params: { method: 'POST', body: data } });
    }

    // COUNTRIES

    listCountries() {
        return this._fetch('/api/countries');
    }

    getCountry(code) {
        return this._fetch(`/api/country/${code}`);
    }

    // SPECIES

    getSpeciesDigest(lang = 'fr') {
        return this._fetch('/api/species/digest', { query: { lang } });
    }

    listSpecies(lang = 'fr') {
        return this._fetch('/api/species', { query: { lang } });
    }

    listOrgSpecies(orgId, lang = 'fr') {
        return this._fetch(`/api/org/${orgId}/species`, { query: { lang } });
    }

    listUserSpecies(userId, lang = 'fr') {
        return this._fetch(`/api/user/${userId}/species`, { query: { lang } });
    }

    // GEARS

    listGears(lang = 'fr') {
        return this._fetch('/api/gears', { query: { lang } });
    }

    listTaggedGears(tag, lang = 'fr') {
        return this._fetch(`/api/gears/${tag}`, { query: { lang } });
    }

    updateGear(id, info) {
        return this._fetch(`/api/gear/${id}`, { params: { method: 'PUT', json: info } });
    }

    // TECHNIQUES

    listTechniques(lang = 'fr') {
        return this._fetch('/api/techniques', { query: { lang } });
    }

    // LANDING PORTS

    listLandingPorts(countryCode = 'fr') {
        return this._fetch(`/api/landing-ports/${countryCode}`);
    }

    // SETTINGS

    getSettings(key, orgId, tagFilter) {
        const query = {};
        if (orgId) {
            query.orgId = orgId;
        }
        if (tagFilter) {
            query.tag = tagFilter;
        }

        return this._fetch(`/api/settings/${key}`, { query });
    }

    updateSettings(key, orgId, tagFilter, settings) {
        const query = {};
        if (orgId) {
            query.orgId = orgId;
        }
        if (tagFilter) {
            query.tag = tagFilter;
        }

        return this._fetch(`/api/settings/${key}`, {
            params: { method: 'PUT', json: settings },
            query
        });
    }

    // ORGS

    listOrgs() {
        return this._fetch('/api/orgs');
    }

    listUserOrgs(userId) {
        return this._fetch(`/api/user/${userId}/orgs`);
    }

    listBuyerOrgs() {
        return this._fetch('/api/buyer-orgs');
    }

    listUserBuyerOrgs(userId) {
        return this._fetch(`/api/user/${userId}/buyer-orgs`);
    }

    getOrgInfo(orgId) {
        return this._fetch(`/api/org/${orgId}`);
    }

    getBuyerOrgInfo(orgId) {
        return this._fetch(`/api/buyer-org/${orgId}`);
    }

    updateBuyerOrgInfo(orgId, info) {
        const data = new FormData();
        data.append('name', info.name);
        data.append('ownerName', info.ownerName);
        data.append('ownerPhone', info.ownerPhone);
        data.append('ownerEmail', info.ownerEmail);
        data.append('companyEmail', info.companyEmail);
        data.append('phoneNumber', info.phoneNumber);
        data.append('address1', info.address1);
        data.append('address2', info.address2);
        data.append('zipCode', info.zipCode);
        data.append('city', info.city);
        data.append('country', info.country);
        data.append('companyId', info.companyId);
        data.append('vatNum', info.vatNum);
        data.append('accreditation', info.accreditation);
        return this._fetch(`/api/buyer-org/${orgId}`, { params: { method: 'PATCH', body: data } });
    }

    // USERS

    listOrgUsers(orgId, filters = {}) {
        return this._fetch(`/api/org/${orgId}/users`, { query: filters });
    }

    listOrgAdmins(orgId, includeReaders = false) {
        return this._fetch(`/api/org/${orgId}/admins`, includeReaders ? { query: { includeReaders } } : undefined);
    }

    listUserOrgAdmins(userId) {
        return this._fetch(`/api/user/${userId}/admin`);
    }

    createUser(orgId, params) {
        const data = new FormData();
        data.append('firstName', params.firstName);
        data.append('lastName', params.lastName);
        data.append('identifier', params.identifier);
        data.append('tags', params.tags);

        return orgId
            ? this._fetch(`/api/org/${orgId}/user`, { params: { method: 'POST', body: data } })
            : this._fetch('/api/detached/user', { params: { method: 'POST', body: data } });
    }

    addExternalUser(orgId, userId) {
        const data = new FormData();
        data.append('orgId', orgId);
        return this._fetch(`/api/user/${userId}/org`, { params: { method: 'POST', body: data } });
    }

    updateOrgMainContact(orgId, contactId) {
        const data = new FormData();
        data.append('contactId', contactId);
        return this._fetch(`/api/org/${orgId}/admin`, { params: { method: 'POST', body: data } });
    }

    getBuyerOrgAdmin(orgId) {
        return this._fetch(`/api/buyer-org/${orgId}/admin`);
    }

    listBuyerOrgCollectors(orgId) {
        return this._fetch(`/api/buyer-org/${orgId}/collectors`);
    }

    listBuyerOrgAdmins(orgId) {
        return this._fetch(`/api/buyer-org/${orgId}/admins`);
    }

    updateBuyerOrgAdmin(orgId, adminId, params) {
        const data = new FormData();
        data.append('firstName', params.firstName);
        data.append('lastName', params.lastName);
        data.append('mobileNumber', params.mobileNum1);
        data.append('email', params.email);
        return this._fetch(`/api/buyer-org/${orgId}/admin/${adminId}`, { params: { method: 'PATCH', body: data } });
    }

    createCollector(orgId, params) {
        const data = new FormData();
        data.append('firstName', params.firstName);
        data.append('lastName', params.lastName);
        data.append('identifier', params.identifier);
        return this._fetch(`/api/buyer-org/${orgId}/collector`, {
            params: { method: 'POST', body: data }
        });
    }

    updateCollector(orgId, collectorId, params) {
        const data = new FormData();
        data.append('firstName', params.firstName);
        data.append('lastName', params.lastName);
        data.append('identifier', params.identifier);
        data.append('mobileNumber', params.mobileNum1);
        data.append('email', params.email);
        return this._fetch(`/api/buyer-org/${orgId}/collector/${collectorId}`, {
            params: { method: 'PATCH', body: data }
        });
    }

    getUserInfo(userId, withBankInfo = false) {
        return this._fetch(`/api/user/${userId}${withBankInfo ? '?withBankInfo=true' : ''}`);
    }

    getUserInfoFromIdentifier(identifier) {
        return this._fetch(`/api/identifier/${identifier}/user`);
    }

    updateUserInfo(userId, params, returnBankInfos = false) {
        const data = new FormData();
        data.append('identifier', params.identifier);
        data.append('title', params.title);
        data.append('firstName', params.firstName);
        data.append('lastName', params.lastName);
        data.append('birthDate', params.birthDate);
        data.append('licenseNum', params.licenseNum);
        data.append('address1', params.address1);
        data.append('address2', params.address2);
        data.append('zipCode', params.zipCode);
        data.append('city', params.city);
        data.append('email', params.email);
        data.append('mobileNum1', params.mobileNum1);
        data.append('mobileNum2', params.mobileNum2);
        data.append('phoneNum', params.phoneNum);
        data.append('status', params.status);
        data.append('statusPSC', params.statusPSC);
        data.append('socialRegime', params.socialRegime);
        data.append('boatName', params.boatName);
        data.append('boatRegistration', params.boatRegistration);

        if (params.customData) {
            data.append('customData', params.customData);
        }

        if (params.firstName2) {
            data.append('firstName2', params.firstName2);
        }

        if (params.identifier2) {
            data.append('identifier2', params.identifier2);
        }

        if (params.identifier3) {
            data.append('identifier3', params.identifier3);
        }

        if (params.tags) {
            data.append('tags', params.tags);
        }

        if (params.uniqueIdentifier) {
            data.append('uniqueIdentifier', params.uniqueIdentifier);
        }

        if (params.password) {
            data.append('password', params.password);
        }

        return this._fetch(`/api/user/${userId}`, {
            params: { method: 'PATCH', body: data },
            query: { withBankInfo: returnBankInfos }
        });
    }

    uploadUserAvatarImage(userId, blob) {
        const formData = new FormData();
        formData.append('uploadFile', blob);
        return this._fetch(`/api/user/${userId}/image`, { params: { method: 'POST', body: formData } });
    }

    listUserAvatarImage(userId) {
        return this._fetch(`/api/user/${userId}/images`);
    }

    deleteUserAvatarImage(userId, imageId) {
        return this._fetch(`/api/user/${userId}/images/${imageId}`, { params: { method: 'DELETE' } });
    }

    getUserBankInfo(userId, companyId) {
        const query = companyId ? `?companyId=${encodeURIComponent(companyId)}` : '';
        return this._fetch(`/api/user/${userId}/bank-info${query}`);
    }

    updateUserBankInfo(userId, info) {
        return this._fetch(`/api/user/${userId}/bank-info`, { params: { method: 'PUT', json: info } });
    }

    generateBankAuthorization(userId, companyId) {
        const query = [];
        if (companyId) {
            query.push(`c=${companyId}`);
        }
        return this._fetch(`/api/user/${userId}/bank-info/auth-pdf?${query.join('&')}`);
    }

    generateTransferOrders(data) {
        return this._fetch(`/api/pescalice/Payment/generateXMLBankDocument`, {
            params: { method: 'PATCH', json: { payments: data } }
        });
    }

    generatePAPTransferOrders(data) {
        return this._fetch(`/api/pescalice/pap/Payment/generateXMLBankDocument`, {
            params: { method: 'PATCH', json: { payments: data } }
        });
    }

    getAllPayments(baseYear) {
        return this._fetch('/api/pescalice/Payment/allByPerson' + (baseYear ? `?baseYear=${baseYear}` : ''));
    }

    getAllPAPPayments(referenceYear) {
        return this._fetch(
            '/api/pescalice/pap/Payment/allByPerson' + (referenceYear ? `?referenceYear=${referenceYear}` : '')
        );
    }

    updatePaymentsByLicenseRequests(userId, data) {
        return this._fetch(`/api/pescalice/Person/${userId}/paymentsByLicenseRequests`, {
            params: { method: 'PATCH', json: data }
        });
    }

    updatePaymentsByLicenseRequestsPAP(userId, data) {
        return this._fetch(`/api/pescalice/pap/Person/${userId}/paymentsByLicenseRequests`, {
            params: { method: 'PATCH', json: data }
        });
    }

    suggestOrgUsers(orgId, query) {
        return this._fetch('/api/user_suggest/org', { query: { q: query, org: orgId } });
    }

    suggestNonOrgUsers(orgId, query) {
        return this._fetch('/api/user_suggest/non-org', { query: { q: query, org: orgId } });
    }

    listUserBoats(userId) {
        return this._fetch(`/api/user/${userId}/boats`);
    }

    // ZONES

    listOrgZones(orgId) {
        return this._fetch(`/api/org/${orgId}/zones`);
    }

    listUserZones(userId, orgIdHint = null) {
        return this._fetch(`/api/user/${userId}/zones`, orgIdHint ? { query: { orgId: orgIdHint } } : undefined);
    }

    createOrgZone(orgId, code, name, meta) {
        const data = new FormData();
        data.append('code', code);
        data.append('name', name);
        data.append('meta', meta);
        return this._fetch(`/api/org/${orgId}/zone`, { params: { method: 'POST', body: data } });
    }

    updateOrgZone(orgId, zoneId, code, name, meta) {
        const data = new FormData();
        data.append('code', code);
        data.append('name', name);
        data.append('meta', meta);
        return this._fetch(`/api/org/${orgId}/zone/${zoneId}`, { params: { method: 'PATCH', body: data } });
    }

    deleteOrgZone(orgId, code) {
        return this._fetch(`/api/org/${orgId}/zone/${code}`, { params: { method: 'DELETE' } });
    }

    // Seasons

    listSeasons(orgId, species) {
        return this._fetch(`/api/org/${orgId}/species/${species}/seasons`);
    }

    createSeason(season) {
        return this._fetch('/api/season', { params: { method: 'POST', json: season } });
    }

    updateSeason(season) {
        return this._fetch(`/api/season/${season.id}`, { params: { method: 'PATCH', json: season } });
    }

    deleteSeason(season) {
        return this._fetch(`/api/season/${season.id}`, { params: { method: 'DELETE' } });
    }

    // LICENSES

    listOrgLicenseSeasons(orgId) {
        return this._fetch(`/api/org/${orgId}/licenses/seasons`);
    }

    listOrgLicenses(orgId, season) {
        return this._fetch(`/api/org/${orgId}/licenses/${season}`);
    }

    listUserLicenses(userId) {
        return this._fetch(`/api/user/${userId}/licenses`);
    }

    listLicensees(licenseId) {
        return this._fetch(`/api/license/${licenseId}/licensees`);
    }

    downloadLicenseAttributions(licenseId) {
        window.location.replace(`/api/license/${licenseId}/attributions.csv`);
    }

    // RULES

    listUserRules(userId) {
        return this._fetch(`/api/user/${userId}/rules`);
    }

    updateUserSpeciesStatusRule(userId, orgId, species, allow) {
        const data = new FormData();
        data.append('orgId', orgId);
        data.append('allowed', allow);
        return this._fetch(`/api/user/${userId}/rule/${species}`, { params: { method: 'PATCH', body: data } });
    }

    updateUserZoneStatusRule(userId, species, zoneId, allow) {
        const data = new FormData();
        data.append('species', species);
        data.append('zoneId', zoneId);
        data.append('allowed', allow);
        return this._fetch(`/api/user/${userId}/rule/zone`, { params: { method: 'PATCH', body: data } });
    }

    // ORG RULES

    listOrgRules(orgId, ruleType) {
        return this._fetch(`/api/org/${orgId}/rules/${ruleType}`);
    }

    createRule(orgId, ruleType, data) {
        return this._fetch(`/api/org/${orgId}/rules/${ruleType}`, { params: { method: 'POST', json: data } });
    }

    updateRule(orgId, ruleType, ruleId, data) {
        return this._fetch(`/api/org/${orgId}/rules/${ruleType}`, { params: { method: 'PUT', json: data } });
    }

    deleteRule(orgId, ruleType, ruleId) {
        return this._fetch(`/api/org/${orgId}/rules/${ruleType}/${ruleId}`, { params: { method: 'DELETE' } });
    }

    // DECLARATION ERRORS

    listOrgErrors(orgId, limit = 10) {
        return this._fetch(`/api/org/${orgId}/errors`, { query: { limit } });
    }

    // DECLARATIONS

    listOrgDeclarations(orgId, speciesCode) {
        return this._fetch(`/api/org/${orgId}/declarations`, { query: { species: speciesCode } });
    }

    listUserDeclarations(userId, params) {
        return this._fetch(`/api/user/${userId}/declarations`, { query: params });
    }

    createDeclaration(userId, params) {
        const data = new FormData();
        data.append('date', params.date);
        data.append('species', params.species);
        data.append('zoneId', params.zoneId);
        data.append('value', params.value);

        if (params.duration) {
            data.append('duration', params.duration);
        }

        if (params.type) {
            data.append('type', params.type);
        }

        if (params.gearId) {
            data.append('gearId', params.gearId);
        }

        if (params.landingPortId) {
            data.append('landingPortId', params.landingPortId);
        }

        if (params.tags) {
            data.append('tags', params.tags);
        }

        return this._fetch(`/api/user/${userId}/declaration`, { params: { method: 'POST', body: data } });
    }

    createDeclarationGroup(userId, declarations) {
        const payload = { userId, declarations };
        return this._fetch('/api/declaration/group', { params: { method: 'POST', json: payload } });
    }

    updateDeclarationGroup(userId, declarations) {
        const payload = { userId, declarations };
        return this._fetch('/api/declaration/group', { params: { method: 'PUT', json: payload } });
    }

    getDeclarationGroup(userId, rootId) {
        return this._fetch(`/api/user/${userId}/declaration/group/${rootId}`);
    }

    updateDeclaration(userId, declId, params) {
        const data = new FormData();
        data.append('date', params.date);
        data.append('species', params.species);
        data.append('zoneId', params.zoneId);
        data.append('value', params.value);

        if (params.duration) {
            data.append('duration', params.duration);
        }

        if (params.tags) {
            data.append('tags', params.tags);
        }

        if (params.gearId) {
            data.append('gearId', params.gearId);
        }

        if (params.landingPortId) {
            data.append('landingPortId', params.landingPortId);
        }

        if (params.buyerOrgId) {
            data.append('buyerOrgId', params.buyerOrgId);
        }

        return this._fetch(`/api/user/${userId}/declaration/${declId}`, { params: { method: 'PATCH', body: data } });
    }

    deprecateDeclaration(userId, declId) {
        return this._fetch(`/api/user/${userId}/declaration/${declId}`, { params: { method: 'DELETE' } });
    }

    getDeclarationCalendar(userId, year) {
        return this._fetch(`/api/user/${userId}/declarations/calendar/${year}`);
    }

    downloadDeclarationsCSV(orgId, species, seasonId) {
        const seasonParam = seasonId ? `&season=${seasonId}` : '';
        window.location.replace(`/api/org/${orgId}/declarations.csv?species=${species}${seasonParam}`);
    }

    downloadUserDeclarationsCSV(userId) {
        window.location.replace(`/api/user/${userId}/declarations.csv`);
    }

    listUserLastDeclarationParameters(userId) {
        return this._fetch(`/api/user/${userId}/declarations/last-params`);
    }

    listUserLastDeclarationSpecies(userId) {
        return this._fetch(`/api/user/${userId}/declarations/last-species`);
    }

    listUserLastDeclarationGears(userId) {
        return this._fetch(`/api/user/${userId}/declarations/last-gears`);
    }

    listUserLastDeclarationZones(userId) {
        return this._fetch(`/api/user/${userId}/declarations/last-zones`);
    }

    listValidations(userId, year) {
        return this._fetch(`/api/user/${userId}/declarations/validations/${year}`);
    }

    validateDeclarations(userId, year, month) {
        return this._fetch(`/api/user/${userId}/declarations/validations/${year}/${month}`, {
            params: { method: 'POST' }
        });
    }

    // STATS

    listSpeciesSeasons(orgId, speciesCode) {
        return this._fetch(`/api/org/${orgId}/species/${speciesCode}/seasons`);
    }

    listSpeciesCumulatives(orgId, speciesCode, seasonId) {
        return this._fetch(`/api/org/${orgId}/species/${speciesCode}/cumulatives`, { query: { season: seasonId } });
    }

    listUserCumulatives(userId) {
        return this._fetch(`/api/user/${userId}/cumulatives`);
    }

    fetchDeclarationsStatistics(filters) {
        return this._fetch('/api/stats', { query: filters });
    }

    downloadDeclarationsStatistics(filters) {
        window.location.replace(`/api/stats.csv?${queryString.stringify(filters)}`);
    }

    // TRANSACTIONS

    listAvailablePricingSpecies(buyerId) {
        return this._fetch(`/api/buyer/${buyerId}/all-species`);
    }

    createPricingSpecies(buyerId, species) {
        const data = new FormData();
        data.append('species', species);

        return this._fetch(`/api/buyer/${buyerId}/species`, {
            params: { method: 'POST', body: data }
        });
    }

    listPricingSpecies(buyerId) {
        return this._fetch(`/api/buyer/${buyerId}/species`);
    }

    listSpeciesPrices(buyerId, species) {
        return this._fetch(`/api/buyer/${buyerId}/species/${species}/prices`);
    }

    createNewPrice(buyerId, species, price, unit, currency) {
        const data = new FormData();
        data.append('species', species);
        data.append('price', price);
        data.append('unit', unit);
        data.append('currency', currency);

        return this._fetch(`/api/buyer/${buyerId}/species/${species}/price`, {
            params: { method: 'POST', body: data }
        });
    }

    listBuyerOrgTransactions(orgId) {
        return this._fetch(`/api/buyer-org/${orgId}/transactions`);
    }

    listPendingTransactions(buyerId) {
        return this._fetch(`/api/buyer/${buyerId}/transactions/pending`);
    }

    listProcessedTransactions(buyerId) {
        return this._fetch(`/api/buyer/${buyerId}/transactions/processed`);
    }

    acceptTransaction(transactionId, buyerId, fishingCardNum, price, value, quality, status = 'accepted') {
        return this.updateTransaction(transactionId, buyerId, fishingCardNum, price, value, quality, status);
    }

    rejectTransaction(transactionId, buyerId) {
        return this.updateTransaction(transactionId, buyerId, null, null, null, null, 'rejected');
    }

    updateTransaction(transactionId, buyerId, fishingCardNum, price, value, quality, status) {
        const data = new FormData();
        data.append('buyerId', buyerId);
        data.append('status', status);

        if (fishingCardNum) {
            data.append('fishingCardNum', fishingCardNum);
        }

        if (price) {
            data.append('price', price.price);
            data.append('unit', price.unit);
            data.append('currency', price.currency);
        }

        if (value) {
            data.append('value', value);
        }

        if (quality) {
            data.append('quality', quality);
        }

        return this._fetch(`/api/transaction/${transactionId}`, { params: { method: 'PATCH', body: data } });
    }

    groupTransactions(name, transactions) {
        const data = new FormData();
        data.append('name', name);
        data.append('transactions', transactions);

        return this._fetch('/api/transaction/batch', { params: { method: 'PATCH', body: data } });
    }

    // COMPANIES

    listCompanies() {
        return this._fetch('/api/companies');
    }

    listUserCompanies(userId) {
        return this._fetch(`/api/user/${userId}/companies`);
    }

    fetchCompany(companyId) {
        return this._fetch(`/api/company/${companyId}`);
    }

    listCompanyMembers(companyId) {
        return this._fetch(`/api/company/${companyId}/members`);
    }

    createCompany(data) {
        return this._fetch('/api/company', { params: { method: 'POST', json: data } });
    }

    updateCompany(id, data) {
        return this._fetch(`/api/company/${id}`, { params: { method: 'PUT', json: data } });
    }

    updateCompanyMembers(companyId, members) {
        return this._fetch(`/api/company/${companyId}/members`, { params: { method: 'PUT', json: members } });
    }

    // [PESCALICE]

    listAdministrativeDivisions() {
        return this._fetch('/api/pescalice/AdministrativeDivision');
    }

    listLicenseProxies() {
        return this._fetch('/api/pescalice/LicenseProxy');
    }

    listLicenseProxiesPAP() {
        return this._fetch('/api/pescalice/pap/ExtractProxy');
    }

    listSeasonsPAP() {
        return this._fetch('/api/pescalice/pap/Season');
    }

    fetchRequestTypeHint(data) {
        return this._fetch('/api/pescalice/LicenseRequest/infoFor', { params: { method: 'POST', json: data } });
    }

    listLicenseRequests() {
        return this._fetch('/api/pescalice/LicenseRequest/waitingForStatus');
    }

    listLicenseRequestsPAP() {
        return this._fetch('/api/pescalice/pap/LicenseRequest/waitingForStatus');
    }

    createLicenseRequest(data) {
        return this._fetch('/api/pescalice/LicenseRequest', { params: { method: 'POST', json: data } });
    }

    deleteLicenseRequest(id) {
        const data = { isManuallyForced: true, forceDelete: true };
        return this._fetch(`/api/pescalice/LicenseRequest/${id}/cancel`, {
            params: { method: 'PATCH', json: data }
        });
    }

    createLicenseRequestPAP(data) {
        return this._fetch('/api/pescalice/pap/LicenseRequest', { params: { method: 'POST', json: data } });
    }

    deleteLicenseRequestPAP(id) {
        const data = { isManuallyForced: true, forceDelete: true };
        return this._fetch(`/api/pescalice/pap/LicenseRequest/${id}/cancel`, {
            params: { method: 'PATCH', json: data }
        });
    }

    updateLicenseRequest(id, data) {
        return this._fetch(`/api/pescalice/LicenseRequest/${id}`, { params: { method: 'PATCH', json: data } });
    }

    updateLicenseRequestPAP(id, data) {
        return this._fetch(`/api/pescalice/pap/LicenseRequest/${id}`, { params: { method: 'PATCH', json: data } });
    }

    fetchLicenseRequest(id) {
        return this._fetch(`/api/pescalice/LicenseRequest/${id}`);
    }

    fetchLicenseRequestPAP(id) {
        return this._fetch(`/api/pescalice/pap/LicenseRequest/${id}`);
    }

    fetchAllLicenseRequestPAP(baseYear) {
        return this._fetch('/api/pescalice/pap/LicenseRequest/all', { query: { baseYear } });
    }

    cancelLicenseRequest(id, data) {
        return this._fetch(`/api/pescalice/LicenseRequest/${id}/cancel`, { params: { method: 'PATCH', json: data } });
    }

    listLicenseContingents(id) {
        return this._fetch(`/api/pescalice/License/${id}/requests`);
    }

    listLicenseContingentsPAP(id) {
        return this._fetch(`/api/pescalice/pap/Extract/${id}/extractRequests`);
    }

    createLicenseSeasonPart(data) {
        return this._fetch('/api/pescalice/LicenseSeasonPart', { params: { method: 'POST', json: data } });
    }

    licenseWithId(id) {
        return this._fetch(`/api/pescalice/License/${id}`);
    }

    licenseQuotaDashboard(id) {
        return this._fetch(`/api/pescalice/Quota/quotaDashboard/${id}`);
    }

    extractQuotaDashboardPAP(id) {
        return this._fetch(`/api/pescalice/pap/Extract/extractDashboard/${id}`);
    }

    listPescaliceUsers(filters) {
        return this._fetch('/api/pescalice/users', { query: filters });
    }

    fetchPescaliceUser(id) {
        return this._fetch(`/api/pescalice/Person/${id}`);
    }

    requestInfoFor(seasonId, requester) {
        const data = { season: seasonId, requestAuthor: { ...requester, telecapecheId: requester.id } };
        return this._fetch(`/api/pescalice/pap/LicenseRequest/infoFor`, { params: { method: 'POST', json: data } });
    }

    listPescaliceUserLicenses(id) {
        return this._fetch(`/api/pescalice/Person/${id}/licenseRequestProxiesByGlobalStatusByBoatByCompany`, {
            query: { fishingType: 'pescalice.peche-embarquee' }
        });
    }

    listPescaliceUserToDos(id, baseYear, licenseType) {
        return this._fetch(`/api/pescalice/Person/${id}/waitingForStatusLicenseRequestsForBaseYear`, {
            query: { baseYear, licenseType }
        });
    }

    listPescaliceUserLicensesPAP(id) {
        return this._fetch(`/api/pescalice/pap/Person/${id}/licenseRequestBySeason`, {
            query: { fishingType: 'pescalice.peche-a-pied' }
        });
    }

    sendLicenseRequestReceiptPEP(data) {
        return this._fetch('/api/pescalice/pep/receipt', { params: { method: 'POST', json: data } });
    }

    sendLicenseRequestReceiptPAP(data) {
        return this._fetch('/api/pescalice/pap/receipt', { params: { method: 'POST', json: data } });
    }

    generateNotificationDoc(userId, companyId, boatId, annualCampaign, seasonalCampaign) {
        const query = [`b=${boatId}`];
        if (companyId) {
            query.push(`c=${companyId}`);
        }
        if (annualCampaign) {
            query.push(`ay=${annualCampaign}`);
        }
        if (seasonalCampaign) {
            query.push(`sy=${seasonalCampaign}`);
        }

        return this._fetch(`/api/user/${userId}/pescalice_notification?${query.join('&')}`);
    }

    generateNotificationDocPAP(userId, referenceYear) {
        return this._fetch('/api/pescalice/pap/Notification', {
            params: { method: 'POST', json: { person: { telecapecheId: userId }, referenceYear } }
        });
    }

    listBoatRegistrationDistricts(countryCode = 'fr') {
        return this._fetch('/api/boat/registration-districts', { query: { country: countryCode } });
    }

    listProducersOrgs(countryCode = 'fr') {
        return this._fetch('/api/producers-orgs', { query: { country: countryCode } });
    }

    listBoats() {
        return this._fetch('/api/boats');
    }

    createBoat(params) {
        return this._fetch('/api/boat', { params: { method: 'POST', json: params } });
    }

    fetchBoat(id) {
        return this._fetch(`/api/boat/${id}`);
    }

    createPescaliceBoat(params) {
        return this._fetch('/api/pescalice/Boat', { params: { method: 'POST', json: params } });
    }

    updateBoat(boatId, params) {
        return this._fetch(`/api/boat/${boatId}`, { params: { method: 'PUT', json: params } });
    }

    listBoatAuditEntries(boatId) {
        return this._fetch(`/api/boat/${boatId}/audit`);
    }

    listBoatPeople(boatId) {
        return this._fetch(`/api/boat/${boatId}/persons`);
    }

    deleteBoatPerson(boatId, personId, reason) {
        return this._fetch(`/api/boat/${boatId}/persons/${personId}`, {
            params: { method: 'DELETE' },
            query: { reason }
        });
    }

    updateBoatPeople(boatId, people) {
        return this._fetch(`/api/boat/${boatId}/persons`, { params: { method: 'PUT', json: people } });
    }

    processGlobalStatus(requestId) {
        return this._fetch(`/api/pescalice/LicenseRequest/${requestId}/processGlobalStatus`);
    }

    saveGlobalStatus(requestId, data) {
        return this._fetch(`/api/pescalice/LicenseRequest/${requestId}/processGlobalStatus`, {
            params: { method: 'PATCH', json: data }
        });
    }

    // Internal helpers

    _url(path, query) {
        let fullPath = path;
        if (query && Object.keys(query).length > 0) {
            const encodedQuery = queryString.stringify(query);
            fullPath = `${path}?${encodedQuery}`;
        }

        return `${location.protocol}//${location.host}${fullPath}`;
    }

    _fetch(path, options = {}) {
        if (!options.params) {
            options.params = {};
        }
        if (!options.params.headers) {
            options.params.headers = {};
        }

        if (options.sessionId || this._sessionId) {
            options.params.headers['X-Session'] = options.sessionId || this._sessionId;
        }

        if (options.params.json) {
            options.params.body = JSON.stringify(options.params.json);
            options.params.headers['Content-Type'] = 'application/json;charset=UTF-8';
            delete options.params.json;
        }

        if (path.startsWith('/api/pescalice')) {
            options.params.headers['PescaliceRequestAuthorIdentifier'] = this._userIdentifier;
            options.params.headers['PescaliceRequestAuthorType'] = this._userType;
        }

        const url = this._url(path, options.query);
        return fetch(url, options.params).then((response) => {
            return response
                .json()
                .then((jdoc) => {
                    if (jdoc.status === 'error') {
                        console.log(jdoc); // eslint-disable-line no-console

                        // FIXME: Using response status / statusText for legacy reasons
                        throw new APIError(response.status, response.statusText, jdoc);
                    }

                    return jdoc;
                })
                .catch((err) => {
                    if (response.status === 401 && !(path === '/api/session' && options.params.method === 'POST')) {
                        document.location.replace('/login');
                        return;
                    }

                    // Rollbar.error('API error', err);
                    if (err instanceof APIError) {
                        throw err;
                    }

                    throw new APIError(response.status, response.statusText);
                });
        });
    }
}

export const api = new Client();
