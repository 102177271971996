import React from 'react';
import classNames from 'classnames';
import { reaction, observable } from 'mobx';
import { inject, observer, disposeOnUnmount } from 'mobx-react';
import ReactForm from 'mobx-react-form';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import { validator } from '@SUPPORT/validator';

@withRouter
@inject('store')
@withTranslation(['login', 'common'])
@observer
export class PasswordForm extends React.Component {
    input = React.createRef();

    @observable showPassword = false;

    constructor(props) {
        super(props);
        this.form = new ReactForm(
            {
                fields: {
                    password: { rules: 'required|between:4,64' }
                }
            },
            {
                plugins: { dvr: validator },
                options: { validateOnChange: true }
            }
        );

        disposeOnUnmount(
            this,
            reaction(
                () => this.props.store.blockClass('passwordForm'),
                (cl) => {
                    if (!cl && this.input.current) {
                        this.input.current.focus();
                    }
                },
                { fireImmediately: true }
            )
        );
    }

    _login = (evt) => {
        evt.preventDefault();
        const password = this.form.select('password').value;
        this.props.store.setPassword(password);
    };

    _resetPassword = (evt) => {
        evt.preventDefault();
        this.props.store.requestPasswordReset(this.props.store.identifier);
    };

    render() {
        const errorClasses = classNames('warning', {
            hidden: !['UNKNOWN_ACCOUNT', 'INVALID_ACCOUNT'].includes(this.props.store.session.error)
        });

        const { t } = this.props;
        return (
            <form className={this.props.store.blockClass('passwordForm')}>
                <h2>{t('login:password-form.title')}</h2>
                <div>
                    <label>{t('common:password')}</label>
                    <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                        <input
                            {...this.form.select('password').bind()}
                            type={this.showPassword ? 'text' : 'password'}
                            autoComplete="password"
                            ref={this.input}
                        />
                        <img
                            src={this.showPassword ? '/graphics/eye.slash.fill@2x.png' : '/graphics/eye.fill@2x.png'}
                            style={{ opacity: 0.5, width: 26, cursor: 'pointer' }}
                            onClick={() => (this.showPassword = !this.showPassword)}
                        />
                    </div>
                </div>
                {this.props.store.session.error === 'INVALID_PASSWORD' && (
                    <p className="warning">{t('login:password-form.invalid-password')}</p>
                )}
                {this.props.store.session.error === 'BLOCKED_USER' && (
                    <p className="warning">{t('login:password-form.blocked-user')}</p>
                )}
                <div>
                    <button disabled={!this.form.isValid} onClick={this._login}>
                        {t('common:connexion')}
                    </button>
                </div>
                <div className="not-implemented">
                    <input type="checkbox" id="loginRememberMe" />
                    <label htmlFor="loginRememberMe">{t('login:password-form.remember-me')}</label>
                </div>
                <div>
                    <a href="#" className="forgot" onClick={this._resetPassword}>
                        {t('login:password-form.forgot-password')}
                    </a>
                    <p className={errorClasses}>{t('login:password-form.unknown-password')}</p>
                </div>
            </form>
        );
    }
}
