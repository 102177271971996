import React, { Fragment } from 'react';
import styled from 'styled-components';
import { inject } from 'mobx-react';

import { IS_PECALICE } from '@SUPPORT/utils';

import { CountryLink } from './CountryLink';
import { Spinner } from '@COMPONENTS/common/Spinner';
import { SVGObject } from '@COMPONENTS/common/SVGObject';

import PescaliceSVG from '@GRAPHICS/licensee/logo.svg';

const PescaliceLogo = styled(PescaliceSVG)`
    --logo-width: 20rem;
    width: var(--logo-width);
    height: calc(var(--logo-width) * 0.18);
    color: var(--dark-blue);
`;

@inject('store')
export class Header extends React.Component {
    _displayCountryPanel = () => {
        this.props.store.setDisplayCountryPanel(true);
    };

    render() {
        return (
            <header>
                <div>
                    <div>
                        {IS_PECALICE ? (
                            <PescaliceLogo />
                        ) : (
                            <Fragment>
                                <SVGObject className="long" objectId="logoTelecapeche" />
                                <SVGObject className="short" objectId="IconTelecapeche" />
                            </Fragment>
                        )}
                        <Spinner />
                    </div>
                    {!IS_PECALICE && (
                        <menu>
                            <ul>
                                <CountryLink
                                    country={this.props.store.app.country}
                                    onClick={this._displayCountryPanel}
                                />
                            </ul>
                        </menu>
                    )}
                </div>
            </header>
        );
    }
}
