import React from 'react';
import ReactForm from 'mobx-react-form';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';

import { validator } from '@SUPPORT/validator';
import { formatPhoneNumber } from '@SUPPORT/utils';

@withRouter
@inject('store')
@withTranslation(['login', 'common'])
@observer
export class SMSValidationForm extends React.Component {
    constructor(props) {
        super(props);
        this.form = new ReactForm(
            {
                fields: {
                    code: { type: 'text', placeholder: 'xxxx', rules: 'required' }
                }
            },
            {
                plugins: { dvr: validator },
                options: { validateOnChange: true }
            }
        );
    }

    _send = (evt) => {
        evt.preventDefault();
        const code = this.form.select('code').value;
        this.props.store.verifySMSCode(code).then(() => {
            if (this.props.store.resetRequest) {
                this.props.history.push(`/pwd_reset/${this.props.store.resetRequest.id}`);
            }
        });
    };

    render() {
        const { t } = this.props;
        const blockData = this.props.store.blockData || {};
        const messageData = {
            mobileNum: blockData.mobileNum ? formatPhoneNumber(blockData.mobileNum) : '',
            email: blockData.email || ''
        };

        return (
            <form className={this.props.store.blockClass('smsValidationForm')}>
                <h2>{t('login:sms-validation.title')}</h2>
                <div>
                    <p>{t('login:sms-validation.message', messageData)} :</p>
                    <input {...this.form.select('code').bind()} />
                </div>
                <div>
                    <button disabled={!this.form.isValid} onClick={this._send}>
                        {t('common:send')}
                    </button>
                </div>
            </form>
        );
    }
}
