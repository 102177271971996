import React from 'react';
import Rollbar from 'rollbar';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import { ErrorPage } from './ErrorPage';

@observer
export class ErrorBoundary extends React.Component {
    @observable hasError = false;

    componentDidCatch(error) {
        this.hasError = true;
        Rollbar.error(error.message, error);
    }

    render() {
        if (this.hasError) {
            return <ErrorPage />;
        }

        return this.props.children;
    }
}
