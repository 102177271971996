import { types, flow, clone } from 'mobx-state-tree';

import { api } from '@SUPPORT/api';
import { i18n, initializeI18N, initializeLocale } from '@SUPPORT/i18n';

import { Country } from '@STORES/common/country';

export const AppStore = types
    .model('AppStore', {
        country: types.maybeNull(Country),
        isReady: false,
        busyCount: 0,
        inModal: false,
        showAlert: false,
        alertTitle: '',
        alertMessage1: '',
        alertMessage2: '',
        alertBtn1Label: '',
        alertBtn2Label: '',
        allowAlertActions: true
    })
    .volatile(() => {
        return {
            alertCallback: null,
            cancelAlertCallback: null,
            modalData: null
        };
    })
    .views((self) => {
        return {
            get language() {
                return self.country ? self.country.languageCode : 'fr';
            },

            get isBusy() {
                return self.busyCount > 0;
            }
        };
    })
    .actions((self) => {
        return {
            loadCountry: flow(function* (namespace) {
                const countryCode = localStorage.getItem('t2.country') || 'fr';
                let response = yield api.getCountry(countryCode);
                if (!response.data) {
                    response = yield api.getCountry('fr');
                }

                const country = Country.create(response.data);
                initializeI18N(namespace, country.languageCode.slice(0, 2));
                initializeLocale(country.languageCode.slice(0, 2), country.currency);

                self.country = country;
            }),

            setReady: () => {
                self.isReady = true;
            },

            setCountry: (country) => {
                i18n.changeLanguage(country.languageCode);
                localStorage.setItem('t2.country', country.code);
                self.country = clone(country);
            },

            setBusy: (busy = true) => {
                self.busyCount += busy ? 1 : -1;
            },

            setAlertBusy: (busy = true) => {
                self.allowAlertActions = !busy;
            },

            setModal: (modal = true, data = null) => {
                self.inModal = modal;
                self.modalData = data;

                const body = document.querySelector('body');
                if (body.classList.contains('tablet')) {
                    if (modal) {
                        body.classList.add('virtualKeyboard');
                    } else {
                        body.classList.remove('virtualKeyboard');
                    }
                }
            },

            alert: (options = {}) => {
                self.alertTitle = options.title;
                self.alertMessage1 = options.message1;
                self.alertMessage2 = options.message2;
                self.alertBtn0Label = options.label0;
                self.alertBtn1Label = options.label1 || i18n.t('common:ok');
                self.alertBtn2Label = options.label2 || i18n.t('common:cancel');
                self.alert0Callback = options.validation0Callback;
                self.alertCallback = options.validationCallback;
                self.cancelAlertCallback = options.cancelCallback;
                self.showAlert = true;
                self.allowAlertActions = true;
                self.inModal = true;
            },

            dismissAlert: () => {
                self.alertTitle = '';
                self.alertMessage1 = '';
                self.alertMessage2 = '';
                self.alertBtn0Label = null;
                self.alertBtn1Label = i18n.t('common:ok');
                self.alertBtn2Label = i18n.t('common:cancel');
                self.alert0Callback = null;
                self.alertCallback = null;
                self.cancelAlertCallback = null;
                self.showAlert = false;
                self.allowAlertActions = true;
                self.inModal = false;
            }
        };
    });
