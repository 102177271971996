import { types, flow, getRoot, clone } from 'mobx-state-tree';

import { Species } from './species';
import { Zone } from './zones';

import { api } from '@SUPPORT/api';
import { userFullName, userInitials } from '@SUPPORT/utils';

export const UserType = types.enumeration('UserType', [
    'user',
    'admin',
    'superadmin',
    'manager',
    'buyer',
    'buyer_admin',
    'superuser',
    'reader'
]);
export const UserStatus = types.enumeration('UserStatus', ['active', 'inactive', 'archived', 'blocked']);

export const UserSpeciesMap = types.map(types.boolean);

export const UserZone = types
    .model('UserZone', {
        zoneId: types.identifierNumber,
        zoneCode: types.string,
        zoneName: types.string,
        zoneTags: types.maybeNull(types.array(types.string)),
        orgId: types.number,
        orgName: types.string,
        species: types.reference(Species),
        allowed: types.boolean
    })
    .actions((self) => {
        return {
            setAllowed: (allowed) => {
                self.allowed = allowed;
            }
        };
    });

const UserOrg = types.model('UserOrg', {
    id: types.identifierNumber,
    code: types.string,
    name: types.string,
    countryCode: types.string
});

export const User = types
    .model('User', {
        id: types.identifierNumber,
        type: UserType,
        status: UserStatus,
        statusPSC: UserStatus,
        identifier: types.string,
        identifier2: types.maybeNull(types.string),
        identifier3: types.maybeNull(types.string),
        uniqueIdentifier: types.maybeNull(types.string),
        title: types.maybeNull(types.enumeration('UserTitle', ['m', 'mme'])),
        firstName: types.string,
        firstName2: types.maybeNull(types.string),
        lastName: types.string,
        birthDate: types.maybeNull(types.Date),
        address1: types.maybeNull(types.string),
        address2: types.maybeNull(types.string),
        zipCode: types.maybeNull(types.string),
        city: types.maybeNull(types.string),
        countryCode: types.maybeNull(types.string),
        socialRegime: types.maybeNull(types.string),
        email: types.maybeNull(types.string),
        licenseNum: types.maybeNull(types.string),
        mobileNum1: types.maybeNull(types.string),
        mobileNum2: types.maybeNull(types.string),
        phoneNum: types.maybeNull(types.string),
        code: types.maybeNull(types.string),
        orgId: types.maybeNull(types.number),
        orgName: types.maybeNull(types.string),
        species: types.maybeNull(types.array(types.reference(Species))),
        zones: types.maybeNull(types.array(types.reference(Zone))),
        lastDeclarationDate: types.maybeNull(types.Date),
        lastDeclarationValue: types.maybeNull(types.number),
        lastDeclarationSpecies: types.maybeNull(types.string),
        creationDate: types.Date,
        tags: types.maybeNull(types.array(types.string)),
        boatName: types.maybeNull(types.string),
        boatRegistration: types.maybeNull(types.string),
        customData: types.maybeNull(types.string),

        companyId: types.maybeNull(types.number),
        companyName: types.maybeNull(types.string),
        companyType: types.maybeNull(types.string),
        companyRegistration: types.maybeNull(types.string),

        speciesMap: types.maybeNull(UserSpeciesMap),
        allZones: types.maybeNull(types.array(UserZone)),
        allOrgs: types.maybeNull(types.array(UserOrg)),

        bankInfos: types.maybeNull(types.string)
    })
    .views((self) => {
        return {
            get initials() {
                return userInitials(self.firstName, self.lastName);
            },

            fullName(lastNameFirst = true) {
                return userFullName(self, lastNameFirst);
            },

            fullCompanyName(fallback = '') {
                if (!self.companyId) {
                    return fallback;
                }

                const parts = [];
                if (self.companyType) {
                    parts.push(self.companyType);
                }
                if (self.companyName) {
                    parts.push(self.companyName);
                }

                if (parts.lenght === 0) {
                    return fallback;
                }

                return parts.join(' ');
            },

            get canSwitchOrg() {
                return (
                    self.type === 'superadmin' ||
                    self.type === 'reader' ||
                    (self.type === 'admin' && self.hasTag('pap'))
                );
            },

            get isNotRestricted() {
                return (
                    self.type === 'superadmin' ||
                    (self.type === 'admin' && !self.isFromAnotherOrg) ||
                    self.type === 'manager'
                );
            },

            get isFromAnotherOrg() {
                const { session } = getRoot(self);
                return session.info.org && self.orgId !== session.info.org.id;
            },

            hasTag(tag) {
                return self.tags && self.tags.includes(tag);
            },

            hasOnlyTag(tag) {
                const tags = (self.tags || []).filter((t) => t !== 'acceptedConditions');
                return tags.length === 1 && tags[0] === tag;
            },

            hasAnyOfTag(tags) {
                return self.tags && self.tags.some((tag) => tags.includes(tag));
            },

            pickTags(tags) {
                return (self.tags || []).filter((tag) => tags.includes(tag));
            }
        };
    })
    .preProcessSnapshot(
        (snapshot) =>
            snapshot && {
                ...snapshot,
                species: snapshot.species === null ? [] : snapshot.species,
                zones: snapshot.zones === null ? [] : snapshot.zones,
                birthDate: typeof snapshot.birthDate === 'string' ? new Date(snapshot.birthDate) : snapshot.birthDate,
                lastDeclarationDate:
                    typeof snapshot.lastDeclarationDate === 'string'
                        ? new Date(snapshot.lastDeclarationDate)
                        : snapshot.lastDeclarationDate,
                creationDate:
                    typeof snapshot.creationDate === 'string' ? new Date(snapshot.creationDate) : snapshot.creationDate
            }
    )
    .actions((self) => {
        return {
            update: (values) => {
                Object.keys(values).forEach((k) => {
                    self[k] = values[k];
                });
            },

            listSpecies: flow(function* () {
                if (self.speciesMap) {
                    return self.speciesMap;
                }

                const { app } = getRoot(self);
                const response = yield api.listUserSpecies(self.id, app.language);

                const species = [];
                const speciesMap = {};
                (response.data || []).forEach((sp) => {
                    species.push(sp.code);
                    speciesMap[sp.code] = sp.allowed;
                });

                self.species = species;
                self.speciesMap = speciesMap;

                return self.speciesMap;
            }),

            listZones: flow(function* (orgIdHint = null) {
                const zones = yield api.listUserZones(self.id, orgIdHint);
                self.allZones = zones.data;
                return self.allZones;
            }),

            listAllOrgs: flow(function* () {
                if (self.allOrgs) {
                    return self.allOrgs;
                }

                const orgs = yield api.listUserOrgs(self.id);
                self.allOrgs = orgs.data;
                return self.allOrgs;
            }),

            listLastDeclarationParams: flow(function* () {
                const params = yield api.listUserLastDeclarationParameters(self.id);
                return params.data;
            }),

            listLastDeclarationZones: flow(function* () {
                const params = yield api.listUserLastDeclarationZones(self.id);
                return params.data;
            }),

            listLastDeclarationSpecies: flow(function* () {
                const params = yield api.listUserLastDeclarationSpecies(self.id);
                return params.data;
            }),

            listLastDeclarationGears: flow(function* () {
                const params = yield api.listUserLastDeclarationGears(self.id);
                return params.data;
            }),

            withCompany: (company) => {
                return clone(self).setCompanyInfo(company);
            },

            setCompanyInfo: (company) => {
                self.companyId = company.id;
                self.companyName = company.name;
                self.companyType = company.type;
                self.companyRegistration = company.registration;
                return self;
            },

            withoutCompanyInfo: () => {
                return clone(self).clearCompanyInfo();
            },

            clearCompanyInfo: () => {
                self.companyId = null;
                self.companyName = null;
                self.companyType = null;
                self.companyRegistration = null;
                return self;
            }
        };
    });

export const UserList = types.array(User);
