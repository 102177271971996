/* global process */

import Rollbar from 'rollbar';

let initialized = false;

export function configureRollbar() {
    if (process.env.NODE_ENV === 'production') {
        Rollbar.init({
            accessToken: '33c4f8f3e7ff45229a370e7c8505c946',
            captureUncaught: true,
            captureUnhandledRejections: true,
            payload: {
                environment: 'production'
            }
        });

        initialized = true;
    }
}

export function setRollbarPayload(payload) {
    if (initialized) {
        Rollbar.configure({ payload });
    }
}
