import React from 'react';
import classNames from 'classnames';
import { inject } from 'mobx-react';

export const FLAGS = {
    uk: '/graphics/flags/United_Kingdom',
    fr: '/graphics/flags/France',
    es: '/graphics/flags/Spain',
    pt: '/graphics/flags/Portugal',
    nl: '/graphics/flags/Netherlands',
    be: '/graphics/flags/Belgium'
};

@inject('store')
export class CountryLink extends React.Component {
    _handleClick = (evt) => {
        evt.preventDefault();
        this.props.onClick(this.props.country);
    };

    render() {
        const flag = FLAGS[this.props.country.code];
        const classes = classNames({ disabled: !this.props.country.isActive });
        return (
            <li className={classes}>
                <a href="#" onClick={this._handleClick}>
                    <img src={`${flag}.png`} srcSet={`${flag}@2x.png 2x`} />
                    {this.props.country.name}
                </a>
            </li>
        );
    }
}
