/* global process */

import escaperegexp from 'lodash.escaperegexp';
import { format as formatDate, parseISO } from 'date-fns';
import { parse as parsePhoneNum, format as formatPhoneNum } from 'libphonenumber-js';

import { i18n, currentFnsLocale, moneyFormatter, fullMoneyFormatter } from './i18n';

// DOMAIN

export const IS_PECALICE =
    window.location.hostname.startsWith('pescalice.') ||
    window.location.hostname.startsWith('test.pescalice.') ||
    window.location.port === '7999';

// CURRENCY CONSTANTS

export const CURRENCY_SYMBOL_FOR_CODE = {
    EUR: '€',
    GBP: '£'
};

export const TRANSACTION_STATUS = {
    fr: {
        pending: 'En attente',
        accepted: 'Validée',
        updated: 'Validée (modif.)',
        rejected: 'Rejetée'
    }
};

// Cookie helpers stolen from w3schools
// https://www.w3schools.com/js/js_cookies.asp

export function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

export function getCookie(cname) {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

export function clearCookie(cname) {
    document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

// Git revision to be inserted in the UI

export function revisionLabel() {
    return `[rev : ${process.env.FRONTEND_REVISION}]`;
}

// UI adornment based on platform

function isiPad() {
    return navigator.userAgent.match(/iPad/i) != null;
}

export function adaptToPlatform() {
    if (isiPad()) {
        const body = document.querySelector('body');
        // const initialWidth = window.innerWidth;
        // const initialHeight = window.innerHeight;

        // window.addEventListener('resize', () => {
        //     if (window.innerWidth !== initialWidth || window.innerHeight !== initialHeight) {
        //         body.classList.toggle('vKeyboard');
        //     }
        // });

        body.classList.add('tablet');
    }
}

// Value formatting

export function padLeft(str, num, ch) {
    str = str.toString();

    if (typeof num === 'undefined') {
        return str;
    }

    if (ch === 0) {
        ch = '0';
    } else if (ch) {
        ch = ch.toString();
    } else {
        ch = ' ';
    }

    return ch.repeat(Math.max(num - str.length, 0)) + str;
}

export function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function capitalizeWords(str) {
    return str.split(' ').map(capitalize).join(' ');
}

export function toISODateString(dateStr) {
    const match = dateStr.match(/(\d\d?)\/(\d\d?)\/(\d\d\d\d)/);
    if (!match) {
        return dateStr;
    }

    return `${match[3]}-${padLeft(match[2], 2, '0')}-${padLeft(match[1], 2, '0')}T13:00:00`;
}

export function formatLocaleDate(date, format) {
    if (typeof date === 'string') {
        date = parseISO(date);
    }
    if (isNaN(date) || date === null || date === undefined) {
        return '';
    }
    return formatDate(date, format, { locale: currentFnsLocale() });
}

export function formatLocalTimezone() {
    const now = new Date();
    const offset = Math.round(now.getTimezoneOffset() / -60);
    return `${offset < 0 ? '-' : '+'}${padLeft(offset, 2, '0')}00`;
}

export function formatDeclarationValue(value) {
    if (!value) {
        return '0 g';
    }

    if (value < 0) {
        return '-';
    }

    const language = i18n.language || 'fr';

    if (value >= 1000) {
        return `${(value / 1000).toLocaleString(language)} kg`;
    }

    return `${value.toLocaleString(language)} g`;
}

export function formatDuration(duration) {
    if (!duration || duration === 0) {
        return '-';
    }

    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;

    if (hours === 0) {
        return `${padLeft(minutes, 2, '0')}mn`;
    }

    if (minutes === 0) {
        return `${hours}h`;
    }

    return `${hours}h${padLeft(minutes, 2, '0')}mn`;
}

export function formatPhoneNumber(number, format = 'National', countryCode = 'FR') {
    if (!number) {
        return number;
    }

    if (countryCode === 'FR' && !number.trim().startsWith('33')) {
        number = '33' + number;
    }

    try {
        const parsedNumber = parsePhoneNum('+' + number, countryCode);
        return formatPhoneNum(parsedNumber.phone, parsedNumber.country, format);
    } catch (err) {
        return number;
    }
}

export function formatPhoneNumberForStorage(number, countryCode) {
    if (!number) {
        return number;
    }

    try {
        const parsedNumber = parsePhoneNum(number, countryCode.toUpperCase());
        return formatPhoneNum(parsedNumber.phone, parsedNumber.country, 'E.164').slice(1);
    } catch (err) {
        return number;
    }
}

export function formatMoneyValue(value, withCurrencySymbol = true) {
    if (!value) {
        return '-';
    }

    return (withCurrencySymbol ? fullMoneyFormatter() : moneyFormatter()).format(value);
}

export function userFullName(user, lastNameFirst = true) {
    if (!user) {
        return '';
    }

    if (user.firstName2) {
        if (lastNameFirst) {
            return `${user.lastName.toUpperCase()} ${user.firstName} ${user.firstName2}`;
        }

        return `${user.firstName} ${user.firstName2} ${user.lastName.toUpperCase()}`;
    }

    if (lastNameFirst) {
        return `${user.lastName.toUpperCase()} ${user.firstName}`;
    }

    return `${user.firstName} ${user.lastName.toUpperCase()}`;
}

export function userInitials(firstName, lastName) {
    let initials = '-';
    if (firstName && lastName) {
        initials = firstName[0] + lastName[0];
    } else if (firstName) {
        initials = firstName[0];
    } else if (lastName) {
        initials = lastName[0];
    }

    return initials.toUpperCase().trim();
}

export function companyFullName(company, fallback = '') {
    if (!company) {
        return fallback;
    }

    const parts = [];
    if (company.type) {
        parts.push(company.type);
    }
    if (company.name) {
        parts.push(company.name);
    }

    if (parts.lenght === 0) {
        return fallback;
    }

    return parts.join(' ');
}

export function parseFloatingNum(input) {
    if (!input) {
        return NaN;
    }

    if (typeof input === 'number') {
        return input;
    }

    return parseFloat(input.toString().replace(/,/, '.'));
}

// Sort filters

export function byKey(key) {
    return (a, b) => a[key].localeCompare(b[key], 'fr', { sensitivity: 'base' });
}

export function byLastName(a, b) {
    return a.lastName.localeCompare(b.lastName, 'fr', { sensitivity: 'base' });
}

export function byFullName(a, b) {
    const fullA = `${a.lastName}${a.firstName}`;
    const fullB = `${b.lastName}${b.firstName}`;
    return fullA.localeCompare(fullB, 'fr', { sensitivity: 'base' });
}

export function byCode(a, b) {
    return a.code.localeCompare(b.code, 'fr', { sensitivity: 'base', numeric: true });
}

// Normalized string matching

function normalizeString(str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function matchNormalized(string, query, caseIndependent = true) {
    const input = normalizeString(string || '');
    const pattern = normalizeString(query || '');
    return caseIndependent ? input.toUpperCase().includes(pattern.toUpperCase()) : input.includes(pattern);
}

// Safe RegExp building

export function safeRegExp(str, flags) {
    return new RegExp(escaperegexp(str.trim()), flags);
}

// Random

export function randomId() {
    return Math.round(Math.random() * 100000);
}

// Error reporting

export function reportError(err) {
    let message = 'Une erreur est survenue:\n';

    if (err) {
        message += err.errorCode ? '• code : ' + err.errorCode + '\n' : '';
        message += err.errorMessage ? '• message : ' + err.errorMessage + '\n' : '';
        message += err.devMessage ? '• info : ' + err.devMessage + '\n' : '';
    } else {
        message += '• aucune information disponible';
    }

    alert(message);
}

// Data download

export function downloadFileFromBase64Data(data, fileName, type = 'text/plain') {
    const urlData = `data:${type};base64,${data}`;
    downloadFile(urlData, fileName);
}

export function downloadFileFromData(blob, fileName) {
    const urlData = URL.createObjectURL(blob);
    downloadFile(urlData, fileName);
    setTimeout(() => URL.revokeObjectURL(urlData), 500);
}

function downloadFile(urlData, fileName) {
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);

    a.href = urlData;
    a.setAttribute('download', fileName);
    a.click();

    document.body.removeChild(a);
}

// CSV

const CSV_SEPARATOR = ';';
const CSV_UTF8_BOM = new Int8Array([0xef, 0xbb, 0xbf]);

export function buildCSVData(headers, rows) {
    const csvData = [headers, ...rows].map((row) => row.join(CSV_SEPARATOR)).join('\r\n');
    return new Blob([CSV_UTF8_BOM, csvData], { type: 'text/csv;charset=utf-8' });
}

// Object properties picking

export function pick(doc, keys) {
    const res = {};

    if (doc) {
        const keySet = new Set(keys);
        Object.keys(doc)
            .filter((k) => keySet.has(k) && doc[k] !== undefined)
            .forEach((k) => (res[k] = doc[k]));
    }

    return res;
}

// Email validation

const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function isValidEmail(email) {
    return EMAIL_PATTERN.test(email);
}

// Temp request storage

export function clearTemporaryPEPRequest() {
    localStorage.removeItem('temp-pep-req');
}

export function saveTemporaryPEPRequest(tempRequest) {
    localStorage.setItem('temp-pep-req', JSON.stringify(tempRequest));
}

export function loadTemporaryPEPRequest() {
    const tempRequestData = localStorage.getItem('temp-pep-req');
    if (tempRequestData) {
        try {
            return JSON.parse(tempRequestData);
        } catch {
            return null;
        }
    }

    return null;
}

// Payment methods formatting

export function paymentMethodLabel(method) {
    switch (method) {
        case 'kPSPaymentMethodCheck':
            return 'Chèque';
        case 'kPSPaymentMethodDebit':
            return 'Prélèvement';
        case 'kPSPaymentMethodCreditCard':
            return 'Carte de crédit';
        case 'kPSPaymentMethodWired':
            return 'Virement';
        case 'kPSPaymentMethodOther':
        default:
            return 'Autre';
    }
}
