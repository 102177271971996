import { types, getRoot, flow } from 'mobx-state-tree';

import { api } from '@SUPPORT/api';
import { byCode } from '@SUPPORT/utils';

export const Zone = types
    .model('Zone', {
        id: types.identifierNumber,
        code: types.string,
        name: types.string,
        orgId: types.number,
        tags: types.maybeNull(types.array(types.string)),
        meta: types.maybeNull(types.string)
    })
    .views((self) => {
        return {
            hasTag(tag) {
                return self.tags && self.tags.includes(tag);
            }
        };
    })
    .actions((self) => {
        return {
            update(code, name, meta) {
                self.code = code;
                self.name = name;
                self.meta = meta;
            }
        };
    });

export const ZonesStore = types
    .model('ZonesStore', {
        list: types.array(Zone)
    })
    .views((self) => {
        return {
            hasZoneWithCode(code) {
                const zone = self.list.find((z) => z.code === code);
                return zone !== undefined;
            },

            withId(zoneId) {
                return self.list.find((z) => z.id === zoneId);
            }
        };
    })
    .actions((self) => {
        const { app, session } = getRoot(self);

        return {
            listAll: flow(function* () {
                app.setBusy();
                try {
                    const sessionInfo = yield session.getSession();
                    const response = yield api.listOrgZones(sessionInfo.org.id);
                    self.list = response.data ? response.data.sort(byCode).map((data) => Zone.create(data)) : [];
                    return self.list;
                } finally {
                    app.setBusy(false);
                }
            }),

            createZone: flow(function* (code, name, meta) {
                app.setBusy();
                try {
                    const sessionInfo = yield session.getSession();
                    const response = yield api.createOrgZone(sessionInfo.org.id, code, name, meta);
                    const zone = response.data ? Zone.create(response.data) : null;
                    if (zone) {
                        self.list.push(zone);
                    }
                    return zone;
                } finally {
                    app.setBusy(false);
                }
            }),

            updateZone: flow(function* (zoneId, code, name, meta) {
                app.setBusy();
                try {
                    const sessionInfo = yield session.getSession();
                    return yield api
                        .updateOrgZone(sessionInfo.org.id, zoneId, code, name, meta)
                        .then(() => self.withId(zoneId).update(code, name, meta));
                } finally {
                    app.setBusy(false);
                }
            }),

            deleteZone: flow(function* (code) {
                app.setBusy();
                try {
                    const sessionInfo = yield session.getSession();
                    yield api.deleteOrgZone(sessionInfo.org.id, code);
                    self.list = self.list.filter((zone) => zone.code !== code);
                } finally {
                    app.setBusy(false);
                }
            })
        };
    });
