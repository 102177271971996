import { types } from 'mobx-state-tree';

export const Country = types.model('Country', {
    code: types.string,
    name: types.string,
    languageCode: types.string,
    languageName: types.string,
    currency: types.string,
    isActive: types.boolean,

    boatRegistrationExp: types.maybeNull(types.string)
});
