import { types, flow, getRoot } from 'mobx-state-tree';

import { api } from '@SUPPORT/api';

const FISHING_TYPE = {
    pae: 'Pêche aux Engins',
    pal: 'Pêche à la Ligne',
    pap: 'Pêche à Pieds',
    pep: 'Pêche Embarquée',
    pfp: 'Pêche Fluviale',
    civ: 'Pêche à la Civelle'
};

export const Org = types
    .model('Org', {
        id: types.number,
        code: types.string,
        name: types.string,
        creationDate: types.Date,
        countryCode: types.maybeNull(types.string),
        contactId: types.maybeNull(types.number),
        features: types.array(types.string),
        tags: types.maybeNull(types.array(types.string))
    })
    .preProcessSnapshot(
        (snapshot) =>
            snapshot && {
                ...snapshot,
                creationDate:
                    typeof snapshot.creationDate === 'string' ? new Date(snapshot.creationDate) : snapshot.creationDate
            }
    )
    .views((self) => {
        return {
            get fishingTypes() {
                return (self.tags || []).reduce((arr, tag) => {
                    if (FISHING_TYPE[tag]) {
                        arr.push({ tag, name: FISHING_TYPE[tag] });
                    }
                    return arr;
                }, []);
            },

            hasTag(tag) {
                return self.tags && self.tags.includes(tag);
            },

            hasFeature(feature) {
                return self.features && self.features.includes(feature);
            }
        };
    })
    .actions((self) => {
        return {
            setContactId: (contactId) => (self.contactId = contactId)
        };
    });

const OrgList = types.array(Org);

export const OrgsStore = types
    .model('OrgsStore', {
        list: types.optional(OrgList, [])
    })
    .views((self) => {
        return {
            byId(id) {
                return self.list.find((org) => org.id === id);
            }
        };
    })
    .actions((self) => {
        const { session } = getRoot(self);
        return {
            listAll: flow(function* () {
                const sessionInfo = yield session.getSession();
                const response = yield api.listOrgs();

                const orgs = response.data || [];
                if (
                    sessionInfo.user.type === 'superadmin' &&
                    sessionInfo.user.tags &&
                    sessionInfo.user.tags.length > 0
                ) {
                    const tag = sessionInfo.user.tags[0];
                    self.list = orgs.filter((data) => data.tags && data.tags.includes(tag));
                } else if (sessionInfo.user.hasTag('pap')) {
                    self.list = orgs.filter((data) => data.tags && data.tags.includes('pap'));
                } else {
                    self.list = orgs;
                }

                self.list = self.list.slice().sort((org1, org2) => {
                    const key1 = `${org1.name.slice(0, 1)}${org1.code}`;
                    const key2 = `${org2.name.slice(0, 1)}${org2.code}`;
                    return key1.localeCompare(key2);
                });
            })
        };
    });
