import React from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import ReactForm from 'mobx-react-form';
import { withTranslation } from 'react-i18next';

import { validator } from '@SUPPORT/validator';

@inject('store')
@withTranslation(['login', 'common'])
@observer
export class IdentifierForm extends React.Component {
    constructor(props) {
        super(props);
        this.form = new ReactForm(
            {
                fields: {
                    identifier: { type: 'text', rules: 'required' } //|regex:/^\\d{2}-\\d{4}$/ for PAP
                }
            },
            {
                plugins: { dvr: validator },
                options: { validateOnChange: true }
            }
        );
    }

    _submit = (evt) => {
        evt.preventDefault();
        const identifier = this.form.select('identifier').value;
        this.props.store.setIdentifier(identifier);
    };

    render() {
        const { t } = this.props;
        const errorClasses = classNames('warning', {
            hidden: !['UNKNOWN_ACCOUNT', 'INVALID_ACCOUNT'].includes(this.props.store.session.error)
        });

        return (
            <form className={this.props.store.blockClass('identifierForm')}>
                <h2>{t('login:identifier-form.title')}</h2>
                <div>
                    <label>{t('common:identifier')}</label>
                    <input {...this.form.select('identifier').bind()} autoFocus autoComplete="username" />
                </div>
                <div>
                    <button disabled={!this.form.isValid} onClick={this._submit}>
                        {t('common:next')}
                    </button>
                </div>
                <div>
                    <p className={errorClasses}>{t('login:identifier-form.unknown-account')}Unknown account.</p>
                </div>
            </form>
        );
    }
}
