import React from 'react';
import ReactForm from 'mobx-react-form';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import { validator } from '@SUPPORT/validator';

@inject('store')
@withTranslation(['login', 'common'])
@observer
export class MissingInfoForm extends React.Component {
    constructor(props) {
        super(props);
        this.form = new ReactForm(
            {
                fields: {
                    phone: { type: 'text', rules: 'required' },
                    email: { type: 'text', rules: 'required|email' }
                }
            },
            {
                plugins: { dvr: validator },
                options: { validateOnChange: true }
            }
        );
    }

    _sendRequest = (evt) => {
        evt.preventDefault();
        // FIXME: What the fuck am I supposed to do here ?
        // const params = Object.assign({ identifier: this.props.store.identifier || '' }, this.form.values());
        // this.props.store.sendProfileRequest(params).then(() => {
        this.props.store.transitionToBlock('unknownUserConfirmation');
        // });
    };

    render() {
        const { t } = this.props;
        const blockData = this.props.store.blockData || {};
        return (
            <form className={this.props.store.blockClass('missingInfoForm')}>
                <h2>{t('login:missing-info-form.title')}</h2>
                <div>
                    <p>
                        {t('login:missing-info-form.message', {
                            firstName: blockData.firstName || '',
                            lastName: blockData.lastName || ''
                        })}
                    </p>
                </div>
                <div>
                    <label>{t('common:mobile-number')} :</label>
                    <input {...this.form.select('phone').bind()} />
                </div>
                <div>
                    <label>{t('common:email')} :</label>
                    <input {...this.form.select('email').bind()} />
                </div>
                <div>
                    <button onClick={this._sendRequest} disabled={!this.form.isValid}>
                        {t('common:send')}
                    </button>
                </div>
            </form>
        );
    }
}
