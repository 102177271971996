import React from 'react';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

@inject('store')
@withTranslation(['login'])
@observer
export class UnknownUserConfirmation extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <form className={this.props.store.blockClass('unknownUserConfirmation')}>
                <h2>{t('login:unknown-user-confirmation.title')}</h2>
                <div>
                    <p>
                        {t('login:unknown-user-confirmation.line1')}
                        <br />
                        {t('login:unknown-user-confirmation.line2')}
                    </p>
                </div>
            </form>
        );
    }
}
