import React from 'react';
import classNames from 'classnames';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

import { revisionLabel } from '@SUPPORT/utils';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

@withTranslation(['common'])
@observer
export class Copyright extends React.Component {
    @observable mounted = false;

    componentDidMount() {
        setTimeout(this._setMounted, 500);
    }

    @action.bound
    _setMounted() {
        this.mounted = true;
    }

    render() {
        const now = new Date();
        const { t } = this.props;
        return (
            <div className="copyright" title={revisionLabel()}>
                <u>
                    Telecapêche (<span className="version">2.00</span>) -{' '}
                </u>
                <i>Copyright ©2017-{now.getFullYear()}</i> <SVGObject objectId="logoManateeLab" />
                {this.props.withLegal && (
                    <a href="/legal" target="_blank">
                        {t('common:legalese')}
                    </a>
                )}
                {this.props.withBigLogo && (
                    <svg
                        className={classNames('telecapecheLogo', { show: this.mounted })}
                        xmlns="http://www.w3.org/2000/svg"
                        width="520"
                        height="274"
                        viewBox="0 0 131 69"
                    >
                        <path fill="#1D76BB" opacity=".948" d="M45.935 0L0 69h131z" />
                        <path fill="#00AEEF" opacity=".85" d="M103.354 21L0 69h131z" />
                        <path fill="#FFCC2B" opacity=".85" d="M12.334 27L0 69h131z" />
                    </svg>
                )}
            </div>
        );
    }
}
