import { types, getRoot, flow, clone } from 'mobx-state-tree';

import { api } from '@SUPPORT/api';
import { setRollbarPayload } from '@SUPPORT/rollbar';

import { User } from './user';
import { Org } from './org';
import { BuyerOrg } from './buyer-org';

export const Permission = types.enumeration('Permission', ['VIEW_BUYERS_INFO']);

const SessionInfo = types
    .model('SessionInfo', {
        id: types.number,
        user: User,
        org: types.maybeNull(
            types.union(
                {
                    dispatcher: (snapshot) => {
                        if (!snapshot) {
                            return Org;
                        }

                        return Object.prototype.hasOwnProperty.call(snapshot, 'contactId') ? Org : BuyerOrg;
                    }
                },
                Org,
                BuyerOrg
            )
        ),
        permissions: types.array(Permission)
    })
    .views((self) => {
        return {
            hasPermission(perm) {
                return self.permissions.includes(perm);
            }
        };
    });

export const SessionStore = types
    .model('SessionStore', {
        info: types.maybeNull(SessionInfo),
        error: types.maybeNull(
            types.enumeration('Error', ['UNKNOWN_ACCOUNT', 'INVALID_PASSWORD', 'INVALID_REQUEST_ID', 'BLOCKED_USER'])
        )
    })
    .volatile(() => {
        return {
            url: null
        };
    })
    .actions((self) => {
        const { app } = getRoot(self);
        let _sessionPromise = null;

        return {
            create: flow(function* (identifier, password) {
                app.setBusy();
                self.error = null;
                try {
                    const response = yield api.createSession(identifier, password);
                    if (response.data) {
                        self.open(response.data.rootURL);
                    }
                } catch (err) {
                    if (err.name === 'APIError' && err.code === 404) {
                        self.error = 'UNKNOWN_ACCOUNT';
                    } else if (err.code === 400 && err.wrapped && err.wrapped.errorCode === 'blocked_user') {
                        self.error = 'BLOCKED_USER';
                    } else if (err.code === 401) {
                        self.error = 'INVALID_PASSWORD';
                    }
                } finally {
                    app.setBusy(false);
                }
            }),

            open: (rootURL) => {
                if (rootURL) {
                    window.location.replace(rootURL);
                }
            },

            destroy: () => {
                app.setBusy();
                api.destroySession().then(() => {
                    window.location.replace('/login');
                });
            },

            getSession: flow(function* (refresh = false) {
                if (_sessionPromise !== null) {
                    return yield _sessionPromise;
                }

                if (self.info && !refresh) {
                    return Promise.resolve(self.info);
                }

                _sessionPromise = self._fetchSessionInfo();
                const info = yield _sessionPromise;
                _sessionPromise = null;

                return info;
            }),

            _fetchSessionInfo: flow(function* () {
                app.setBusy();
                try {
                    const response = yield api.getSession();
                    if (response && response.data) {
                        setRollbarPayload({
                            user: response.data.user,
                            org: response.data.org
                        });

                        self.info = SessionInfo.create(response.data);
                        return self.info;
                    }

                    return null;
                } finally {
                    app.setBusy(false);
                }
            }),

            selectOrg: (org) => {
                self.info.org = clone(org);
            }
        };
    });
