import React from 'react';
import { inject, observer } from 'mobx-react';

@inject('store')
@observer
export class UnknownPescaliceUser extends React.Component {
    _backToIdentifier = (evt) => {
        evt.preventDefault();
        this.props.store.transitionToBlock('identifierForm');
    };

    render() {
        return (
            <form className={this.props.store.blockClass('unknownPescaliceUser')}>
                <h2>Identifiant inconnu</h2>
                <div>
                    <p>Merci de vérifier votre saisie.</p>
                    <p style={{ maxWidth: '100%' }}>
                        Rappel : pour la Pêche Embarquée, votre login est votre <b>Numéro de Marin</b> ou <b>SPR</b>{' '}
                        (xxAxxxx ou SPRxxxx) ou <b>numéro Pêche à Pied</b> (ex: 22-1234)
                    </p>
                    <p style={{ maxWidth: '100%' }}>Si le problème persiste, contacter votre CDPMEM.</p>
                </div>
                <div>
                    <a onClick={this._backToIdentifier} href="#">
                        Réessayer
                    </a>
                </div>
            </form>
        );
    }
}
