import React, { Suspense } from 'react';
import { inject, observer } from 'mobx-react';
import { I18nextProvider } from 'react-i18next';

import { i18n } from '@SUPPORT/i18n';

@inject('store')
@observer
export class LanguageLoader extends React.Component {
    componentDidMount() {
        this.props.store.app.loadCountry(this.props.namespace);
    }

    render() {
        if (!this.props.store.app.country) {
            return null;
        }

        return (
            <Suspense fallback={null}>
                <I18nextProvider i18n={i18n}>{this.props.children}</I18nextProvider>
            </Suspense>
        );
    }
}
