import React from 'react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import { CountryLink } from './CountryLink';
import { SVGObject } from '@COMPONENTS/common/SVGObject';

@inject('store')
@withTranslation(['login', 'common'])
@observer
export class CountryPanel extends React.Component {
    _selectCountry = (country) => {
        this.props.store.app.setCountry(country);
        this.props.store.setDisplayCountryPanel(false);
    };

    _dismiss = (evt) => {
        evt.preventDefault();
        this.props.store.setDisplayCountryPanel(false);
    };

    render() {
        const classes = classNames('modal', 'changeCountry', { show: this.props.store.displayCountryPanel });
        const countries = this.props.store.countries
            .filter((country) => country.code !== this.props.store.app.country.code)
            .map((country) => {
                return <CountryLink country={country} key={country.code} onClick={this._selectCountry} />;
            });

        const { t } = this.props;
        return (
            <div className={classes}>
                <div className="sheet">
                    <div className="title">
                        <h3>{t('login:country-panel.title')}</h3>
                        <SVGObject objectId="exitCross" onClick={this._dismiss} />
                    </div>
                    <div className="scroll">
                        <ul>{countries}</ul>
                    </div>
                    <div className="exit">
                        <div />
                        <div>
                            <button onClick={this._dismiss}>{t('common:cancel')}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
